import * as axiosRequests from "./axiosRequests";
import { getEndpoint, requestHeaders } from "../utils/endpoint.js";

const CONTRACTS_ENDPOINT = getEndpoint("api/v1.0/contracts");
const CONTRACT_ENDPOINT = (id) => getEndpoint(`api/v1.0/contracts/${id}`);

export const putContract = async (token, contractId) => {
  const response = await axiosRequests.axiosPut(
    CONTRACT_ENDPOINT(contractId),
    {},
    requestHeaders(token)
  );

  return response.data;
};

export const getContracts = async (token) => {
  const response = await axiosRequests.axiosGet(
    CONTRACTS_ENDPOINT,
    {},
    requestHeaders(token)
  );

  return response.data;
};
