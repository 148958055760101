import React from "react";
import { inject, observer } from "mobx-react";
import { NavLink } from "react-router-dom";
import { ContractSelector } from "../../components/ContractSelector/ContractSelector";
import { appStorePropTypes } from "../../propTypes/stores";
import { hasWebAccess } from "../../utils/permissions";
import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import PeopleIcon from "@mui/icons-material/People";
import StorageIcon from "@mui/icons-material/Storage";
import WorkIcon from "@mui/icons-material/Work";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import "./NavigationBar.scss";

@inject("store")
@observer
class NavigationBar extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  render() {
    const { store } = this.props;
    const { companiesStore, currentUser, isAuthenticated } = store;

    if (!isAuthenticated || !hasWebAccess(currentUser)) {
      return null;
    }

    return (
      <div className="Sidebar">
        <ul className="SidebarList">
          {currentUser.attributes.contractCount > 1 && <ContractSelector />}
          <div className="category">Game Management</div>
          <NavLink className="row" end to={`/`}>
            <div id="icon">
              <HomeIcon />
            </div>
            <div id="title">Current Game</div>
          </NavLink>
          {companiesStore.activeCompany && (
            <>
              <NavLink className="row" to={`pastGames`}>
                <div id="icon">
                  <HistoryIcon />
                </div>
                <div id="title">Past Games</div>
              </NavLink>
              <NavLink
                className="row"
                to={`/companies/${companiesStore.activeCompany.id}/winConditions`}
              >
                <div id="icon">
                  <EmojiEventsIcon />
                </div>
                <div id="title">Win Conditions</div>
              </NavLink>
              <NavLink
                className="row"
                to={`/companies/${companiesStore.activeCompany.id}/messages`}
              >
                <div id="icon">
                  <StorageIcon />
                </div>
                <div id="title">All Messages</div>
              </NavLink>
              <div className="category">Staff Management</div>
              <NavLink
                className="row"
                to={`/companies/${companiesStore.activeCompany.id}/users`}
              >
                <div id="icon">
                  <PeopleIcon />
                </div>
                <div id="title">Users</div>
              </NavLink>
              <NavLink
                className="row"
                to={`/companies/${companiesStore.activeCompany.id}/jobSites`}
              >
                <div id="icon">
                  <WorkIcon />
                </div>
                <div id="title">Job Sites</div>
              </NavLink>
            </>
          )}
        </ul>
      </div>
    );
  }
}

export { NavigationBar };

export default NavigationBar;
