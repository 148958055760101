import { action, computed, makeObservable, observable } from "mobx";
import * as companiesService from "../services/companies";
import * as companiesJobSitesService from "../services/companies/jobSites";

export class CompaniesStore {
  @observable companies = [];
  @observable isLoading = false;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
    this.bannerStore = appStore.bannerStore;
  }

  @computed get activeCompany() {
    const { currentUser } = this.appStore;
    const { companyId } = currentUser.attributes.activeContract;

    return this.getCompanyById(companyId);
  }

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  getCompanyById = (companyId) => {
    const company = this.companies.find(
      (company) => company.id === companyId.toString()
    );

    return company;
  };

  @action
  setCompanies = (companies) => {
    this.companies = companies;
  };

  @action
  setCompany = (companyId, company) => {
    const companies = this.companies;
    const companyIndex = companies.findIndex(
      (company) => company.id === companyId.toString()
    );

    if (companyIndex !== -1) {
      companies[companyIndex] = company;
    } else {
      companies.push(company);
    }

    this.setCompanies(companies);
  };

  @action
  updateCompanyField = (companyId, fieldId, newValue) => {
    const company = this.getCompanyById(companyId);

    if (company) {
      company.attributes[fieldId] = newValue;

      this.setCompany(companyId, company);
    }
  };

  @action
  updateCompany = async (companyId, payload) => {
    const token = this.appStore.token;

    this.setIsLoading(true);

    let updateSuccessful;
    try {
      const company = await companiesService.putCompany(
        token,
        companyId,
        payload
      );

      this.setCompany(companyId, company);

      updateSuccessful = true;
    } catch (err) {
      updateSuccessful = false;
    }

    this.setIsLoading(false);

    return updateSuccessful;
  };

  @action
  retrieveCompany = async (companyId) => {
    this.setIsLoading(true);

    const token = this.appStore.token;
    const company = await companiesService.getCompany(token, companyId);

    this.setCompany(companyId, company);

    this.setIsLoading(false);
  };

  @action
  createJobSiteForCompany = async (companyId, payload) => {
    const token = this.appStore.token;

    await companiesJobSitesService.postJobSite(token, companyId, payload);
    const allJobSites = await companiesJobSitesService.getJobSites(
      token,
      companyId
    );

    this.updateCompanyField(companyId, "jobSites", allJobSites.data);
  };

  @action
  deleteJobSiteForCompany = async (companyId, jobSiteId) => {
    const token = this.appStore.token;

    await companiesJobSitesService.deleteJobSite(token, companyId, jobSiteId);
    const allJobSites = await companiesJobSitesService.getJobSites(
      token,
      companyId
    );

    this.updateCompanyField(companyId, "jobSites", allJobSites);
  };
}

export default CompaniesStore;
