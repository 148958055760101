import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Table.scss";

export class Table extends React.Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
  };

  renderHeaders = () => {
    const { headers } = this.props;

    return (
      <tr>
        {headers.map((column) => {
          const style = {};

          if (column.width) {
            style["width"] = column.width;
          }

          return (
            <th key={column.accessor} style={style}>
              {column.header}
            </th>
          );
        })}
      </tr>
    );
  };

  renderRows = () => {
    const { data, headers } = this.props;

    console.log(data);

    return data.map((row) => (
      <tr key={row.id}>
        {headers.map((header) => (
          <td key={header.accessor}>{row[header.accessor]}</td>
        ))}
      </tr>
    ));
  };

  render() {
    const { className } = this.props;

    const tableClass = classNames("Table", className);

    return (
      <table className={tableClass}>
        <thead>{this.renderHeaders()}</thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }
}
