import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import DnDCalendar from "../../../controls/Calendar/Calendar";
import moment from "moment";

@inject("store")
@observer
class BingoRoundsCalendar extends React.Component {
  static propTypes = {
    store: PropTypes.object,
    bingoRounds: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.object,
        id: PropTypes.string,
      })
    ),
  };

  formatEventTitle = (item) => {
    const category = item.attributes.content.attributes.category;
    const draw = item.attributes.formattedDraw;

    if (category) {
      return `${draw}: ${category}`;
    } else {
      return `${draw}: General`;
    }
  };

  onMove = async (id, start) => {
    const { store } = this.props;
    const { bingoRoundsStore } = store;

    const payload = {
      draw_at: start,
    };

    await bingoRoundsStore.updateBingoRound(id, payload);
  };

  createEventsFromData = () => {
    const { bingoRounds } = this.props;

    return bingoRounds.map((item) => {
      return {
        id: item.id,
        title: this.formatEventTitle(item),
        start: moment(item.attributes.drawAt).startOf("day").toDate(),
        end: moment(item.attributes.drawAt).startOf("day").toDate(),
      };
    });
  };

  render() {
    const events = this.createEventsFromData();

    return <DnDCalendar events={events} onMove={this.onMove} />;
  }
}

export { BingoRoundsCalendar };
