import React from "react";
import { inject, observer } from "mobx-react";
import { Table } from "../../../../components/Table/Table";

const COLUMNS = [
  {
    header: "USER",
    accessor: "name",
  },
  {
    header: "ID",
    accessor: "employeeId",
  },
  {
    header: "JOBSITE",
    accessor: "jobSite",
  },
  {
    header: "SUPERVISOR",
    accessor: "supervisorName",
  },
  {
    header: "EMAIL",
    accessor: "email",
  },
];

export const UsersTable = inject((store) => store)(
  observer(({ store }) => {
    const data = store.usersStore.users.map((user) => {
      const { attributes } = user;

      return {
        email: attributes.email,
        name: attributes.fullName,
        employeeId: attributes.employeeId,
        jobSite: attributes.jobSite && attributes.jobSite.attributes.name,
        supervisorName:
          attributes.supervisor && attributes.supervisor.attributes.fullName,
      };
    });

    return (
      <div className="scrollable-table-container">
        <Table headers={COLUMNS} data={data} />
      </div>
    );
  })
);
