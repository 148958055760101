import React from "react";
import { appStorePropTypes } from "../../propTypes/stores";
import { inject, observer } from "mobx-react";
import { VerifiedButton } from "./VerifiedButton";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark.svg";

const TABLE_COLUMNS = [
  {
    header: "WINNER",
    accessor: "name",
  },
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "JOBSITE",
    accessor: "jobsite",
  },
  {
    header: "EMAIL",
    accessor: "email",
  },
  {
    header: "WIN PATTERN",
    accessor: "winpattern",
  },
  {
    header: "VERIFIED",
    accessor: "verified",
  },
];

@inject("store")
@observer
export class WinnerTable extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };

  tableDataFormatted = () => {
    const { gameSessionsStore } = this.props.store;
    const { activeGameSession } = gameSessionsStore;
    const { wins } = activeGameSession.attributes;

    return wins.map((win) => {
      const { user, verified, winCondition } = win.attributes;

      let patternId = "Unknown";
      if (winCondition) {
        patternId = winCondition.attributes.id;
      }

      return {
        key: win.id,
        name: user.attributes.fullName,
        id: user.id,
        jobsite: null, // TODO
        email: user.attributes.email,
        winpattern: patternId, // TODO
        verified: verified ? (
          <Checkmark />
        ) : (
          <VerifiedButton user={user} win={win} />
        ),
      };
    });
  };

  renderHeaders = () => {
    return (
      <tr>
        {TABLE_COLUMNS.map((column) => (
          <th key={column.accessor}>{column.header}</th>
        ))}
      </tr>
    );
  };

  renderCellsForRow = (row) => {
    return TABLE_COLUMNS.map((column) => (
      <td key={column.accessor}>{row[column.accessor]}</td>
    ));
  };

  renderRows = () => {
    const tableData = this.tableDataFormatted();

    return tableData.map((row) => {
      return <tr key={row.key}>{this.renderCellsForRow(row)}</tr>;
    });
  };

  render() {
    return (
      <table>
        <thead>{this.renderHeaders()}</thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }
}

export default WinnerTable;
