import React from "react";
import PropTypes from "prop-types";
import { appStorePropTypes } from "../../propTypes/stores";
import { withRouter } from "../../utils/withRouter";
import { inject, observer } from "mobx-react";
import { Container, Text } from "../../controls";
import { AdminPanelLink } from "./AdminPanelLink";

import "./AdminPanel.scss";

@inject("store")
@observer
class AdminPanel extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
    router: PropTypes.object,
  };

  componentDidMount() {
    const { router, store } = this.props;
    const { id } = router.params;
    const { companiesStore } = store;

    companiesStore.retrieveCompany(id);
  }

  render() {
    const { router, store } = this.props;
    const { companiesStore } = store;
    const { isLoading } = companiesStore;

    return (
      !isLoading && (
        <Container className="AdminPanel middle content-center">
          <Text type="H1">Admin Panel</Text>
          <div className="AdminPanelLinks">
            <AdminPanelLink
              icon="Pencil"
              title="Edit Company Details"
              url={`${router.location.pathname}/edit`}
            />
            <AdminPanelLink
              icon="Users"
              title="User List"
              url={`${router.location.pathname}/users`}
            />
          </div>
        </Container>
      )
    );
  }
}

export default withRouter(AdminPanel);
