import * as axiosRequests from "./axiosRequests";
import { getEndpoint, requestHeaders } from "../utils/endpoint.js";

const COMPANY_ENDPOINT = (id) => getEndpoint(`api/v1.0/companies/${id}`);

export const putCompany = async (token, companyId, payload) => {
  const response = await axiosRequests.axiosPut(
    COMPANY_ENDPOINT(companyId),
    {
      company: payload,
    },
    requestHeaders(token)
  );

  return response.data;
};

export const getCompany = async (token, companyId) => {
  const response = await axiosRequests.axiosGet(
    COMPANY_ENDPOINT(companyId),
    {},
    requestHeaders(token)
  );

  return response.data;
};
