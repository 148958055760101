import React from "react";
import { inject, observer } from "mobx-react";
import { bannerStorePropTypes } from "../../propTypes/stores";
import getTestIDs from "../../utils/getTestIDs";
import Banner from "../Navigation/Banner";
import "./BannerColumn.scss";

export const testIDs = getTestIDs();

@inject("bannerStore")
@observer
class BannerColumn extends React.Component {
  static propTypes = {
    bannerStore: bannerStorePropTypes,
  };

  onBannerClose = (id) => {
    const { bannerStore } = this.props;

    bannerStore.deleteBanner(id);
  };

  renderBanners = () => {
    const { bannerStore } = this.props;

    return bannerStore.banners.map((banner, index) => (
      <Banner
        key={banner.id}
        id={banner.id}
        color={banner.color}
        title={banner.title}
        body={banner.body}
        onClose={this.onBannerClose}
        index={index}
      />
    ));
  };

  render() {
    return (
      <div
        className="BannerColumn__component"
        data-testid={testIDs.bannerStore}
      >
        {this.renderBanners()}
      </div>
    );
  }
}

export default BannerColumn;
