import { action, makeObservable, observable } from "mobx";
import * as usersService from "../services/users";
import { CompaniesStore } from "./CompaniesStore";
import { ContractsStore } from "./ContractsStore";
import { BingoRoundsStore } from "./BingoRoundsStore";
import { GameSessionsStore } from "./GameSessionsStore";
import { ContentsStore } from "./ContentsStore";
import { WinConditionsStore } from "./WinConditionsStore";
import { CardsStore } from "./CardsStore";
import { UsersStore } from "./UsersStore";

const TOKEN_STORAGE_KEY = "_token";

export class AppStore {
  @observable token;
  @observable currentUser;
  @observable isLoading;
  @observable isAuthenticated;

  constructor(bannerStore) {
    makeObservable(this);

    this.bannerStore = bannerStore;
    this.token = this.loadJWTFromStorage();
    this.currentUser = null;
    this.isLoading = true;
    this.isAuthenticated = false;

    this.bingoRoundsStore = new BingoRoundsStore(this);
    this.cardsStore = new CardsStore(this);
    this.companiesStore = new CompaniesStore(this);
    this.contractsStore = new ContractsStore(this);
    this.gameSessionsStore = new GameSessionsStore(this);
    this.usersStore = new UsersStore(this);
    this.contentsStore = new ContentsStore(this);
    this.winConditionsStore = new WinConditionsStore(this);

    this.retrieveCurrentUser();
  }

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  setIsAuthenticated = (isAuthenticated) => {
    this.isAuthenticated = isAuthenticated;
  };

  @action
  setUser = (user) => {
    this.currentUser = user;
  };

  @action
  setToken = (token) => {
    this.token = token;
  };

  loadJWTFromStorage = () => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);

    return token;
  };

  retrieveCurrentUser = async () => {
    try {
      const response = await usersService.getMe(this.token);

      this.setUser(response.data);

      const { activeCompany } = response.data.attributes;

      this.companiesStore.setCompany(activeCompany.id, activeCompany);
      this.setIsAuthenticated(true);
      this.setIsLoading(false);
    } catch (err) {
      console.log(err);
      this.setIsAuthenticated(false);
      this.setIsLoading(false);
    }
  };

  signIn = async (email, password) => {
    try {
      const response = await usersService.signIn(email, password);

      this.token = response.token;

      localStorage.setItem(TOKEN_STORAGE_KEY, this.token);

      await this.retrieveCurrentUser();
      this.setIsAuthenticated(true);

      return true;
    } catch (err) {
      this.token = undefined;
      this.setIsAuthenticated(false);
      return false;
    }
  };

  signOut = async () => {
    if (!this.currentUser) {
      return true;
    }

    await usersService.signOut(this.token);
    this.setIsAuthenticated(false);

    this.setUser(null);
    this.setToken(null);
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  };
}

export default AppStore;
