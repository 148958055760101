import React from "react";
import PropTypes from "prop-types";
import { Provider } from "mobx-react";
import BannerColumn from "../layout/BannerColumn/BannerColumn";
import { BannerStore } from "../stores/BannerStore";
import { ErrorBoundary } from "./ErrorBoundary";

export const bannerStore = new BannerStore();

export class BannerProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;
    return (
      <Provider bannerStore={bannerStore}>
        <ErrorBoundary>
          <BannerColumn />
          {children}
        </ErrorBoundary>
      </Provider>
    );
  }
}

export default BannerProvider;
