import * as axiosRequests from "./axiosRequests";
import { getEndpoint, requestHeaders } from "../utils/endpoint.js";

const BINGO_ROUND_ENDPOINT = (id) => getEndpoint(`api/v1.0/bingo_rounds/${id}`);

export const putBingoRound = async (token, bingoRoundId, payload) => {
  const response = await axiosRequests.axiosPut(
    BINGO_ROUND_ENDPOINT(bingoRoundId),
    {
      bingo_round: payload,
    },
    requestHeaders(token)
  );

  return response.data;
};
