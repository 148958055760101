import PropTypes from "prop-types";

export const companyPropTypes = PropTypes.shape({
  id: PropTypes.string,
});

export const contentsPropTypes = PropTypes.shape({
  id: PropTypes.string,
});

export const currentUserPropTypes = PropTypes.shape({
  id: PropTypes.string,
});

export const winConditionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    exact: PropTypes.bool,
    pattern: PropTypes.array,
  }),
});

export const gameSessionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  attributes: PropTypes.shape({
    round_frequency: PropTypes.object,
    id: PropTypes.number,
    companyId: PropTypes.number,
    userId: PropTypes.number,
    createdAt: PropTypes.string,
    finishedAt: PropTypes.string,
    status: PropTypes.string,
  }),
});
