import React from "react";
import { inject, observer } from "mobx-react";
import { appStorePropTypes } from "../../propTypes/stores";
import { SitesTable } from "./SitesTable/SitesTable";
import { NewSiteModal } from "./NewSiteModal/NewSiteModal";

@inject("store")
@observer
export class JobSites extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  setIsModalOpen(isModalOpen) {
    this.setState({ isModalOpen });
  }

  render() {
    const { store } = this.props;
    const { isModalOpen } = this.state;
    const { companiesStore } = store;
    const { isLoading } = companiesStore;

    return (
      <div className="page-wrap">
        <div className="search-filter-add-row">
          <button onClick={() => this.setIsModalOpen(true)}>Add Site</button>
        </div>
        <div>
          <NewSiteModal
            isOpen={isModalOpen}
            onRequestClose={() => this.setIsModalOpen(false)}
          />
        </div>
        {isLoading ? <div>Loading...</div> : <SitesTable />}
      </div>
    );
  }
}

export default JobSites;
