import { action, makeObservable, observable } from "mobx";
import * as sessionCardsService from "../services/gameSessions/cards";
import * as arrayUtils from "../utils/array";

export class CardsStore {
  @observable cards = [];
  @observable isLoading = true;
  @observable errorMessage;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setError(errorMessage) {
    this.errorMessage = errorMessage;
  }

  @action
  setCards(cards) {
    this.cards = cards;
  }

  @action
  retrieveCardsForGameSession = async (gameSessionId) => {
    try {
      const { token } = this.appStore;

      const response = await sessionCardsService.getCards(token, gameSessionId);

      this.cacheCards(response.data);
    } catch (err) {
      this.setError(err.message);
    }
  };

  @action
  cacheCards(newCards) {
    const allCards = [...newCards, ...this.cards];

    const allCardsUnique = arrayUtils.uniqueOnId(allCards, "id");

    this.setCards(allCardsUnique);
  }

  getCardsForGameSession = (gameSessionId) => {
    const rounds = this.cards.filter(
      (card) =>
        card.attributes.gameSessionId.toString() === gameSessionId.toString()
    );

    return rounds;
  };
}
