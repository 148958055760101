import { action, computed, makeObservable, observable } from "mobx";
import * as companiesUsersService from "../services/companies/users";

export class UsersStore {
  @observable users = [];
  @observable isLoading = true;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
  }

  @computed get supervisors() {
    return this.users.filter((user) => user.attributes.type === "Manager");
  }

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  setUsers = (users) => {
    this.users = users;
  };

  @action
  addUserToUsers = (newUser) => {
    const userList = this.users;

    userList.push(newUser);

    this.setUsers(userList);
  };

  @action
  retrieveUsersAsync = async () => {
    const { companiesStore, token } = this.appStore;
    const { activeCompany } = companiesStore;

    // set is loading to true
    this.setIsLoading(true);

    // make the request to get the users
    try {
      const response = await companiesUsersService.getCompanyUsers(
        token,
        activeCompany.id
      );

      const users = response.data.data;

      this.setUsers(users);
    } catch (err) {
      console.log(err);
    }

    // set is loading to false
    this.setIsLoading(false);
  };

  @action
  createUser = async (userPayload) => {
    const { companiesStore, token } = this.appStore;
    const { activeCompany } = companiesStore;
    // set the loading state to true
    this.setIsLoading(true);

    try {
      // make request to create the user
      const response = await companiesUsersService.postUser(
        token,
        activeCompany.id,
        userPayload
      );

      // parse the response to get the new user
      const newUser = response.data;

      // add the user to the store
      this.addUserToUsers(newUser);
    } catch (err) {
      this.setIsLoading(false);
      throw new Error(err);
    }

    // set the loading state to false
    this.setIsLoading(false);
  };
}

export default UsersStore;
