export const base_url = process.env.REACT_APP_BACKEND_BASE_URL;

export const getEndpoint = (action) => {
  return `${base_url}/${action}`;
};

export const requestHeaders = (token, additionalHeaders = {}) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    ...additionalHeaders,
  };

  return headers;
};
