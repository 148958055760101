import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { CheckboxField, Confirm, Text } from "../../../controls";
import * as winsService from "../../../services/wins";

export const VerifiedButton = inject((store) => store)(
  observer((props) => {
    const { store, win } = props;

    const [isChecked, setIsChecked] = useState(
      win.attributes.verifiedAt !== null
    );
    const [confirmIsOpen, setConfirmIsOpen] = useState(false);

    function onCheckboxClick() {
      setIsChecked(true);
      setConfirmIsOpen(true);
    }

    async function markWinAsVerified() {
      const { token } = store;

      await winsService.updateWin(token, win.id, {
        mark_verified: true,
      });

      closeConfirm();
    }

    function closeConfirm() {
      setConfirmIsOpen(false);
    }

    function cancelVerification() {
      setIsChecked(false);
      closeConfirm();
    }

    return (
      <div className="VerifiedButton">
        <CheckboxField isChecked={isChecked} onChange={onCheckboxClick} />
        <Confirm
          onConfirm={markWinAsVerified}
          onRequestClose={cancelVerification}
          isOpen={confirmIsOpen}
        >
          <Text type="H3">Are you sure you want to verify this win?</Text>
        </Confirm>
      </div>
    );
  })
);
