import React from "react";
import { Row } from "./Row";
import moment from "moment";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "../../../controls";

import "./BingoRoundsTable.scss";

const TABLE_COLUMNS = [
  {
    header: "MESSAGE",
    accessor: "message",
  },
  {
    header: "ANSWER",
    accessor: "answer",
  },
  {
    header: "SHOW DATE",
    accessor: "date",
  },
  {
    header: "Draw",
    accessor: "draw",
  },
  {
    header: "",
    accessor: "edit",
  },
];

export class BingoRoundsTable extends React.Component {
  static propTypes = {
    bingoRounds: PropTypes.arrayOf(
      PropTypes.shape({
        attributes: PropTypes.object,
        id: PropTypes.string,
      })
    ),
  };

  renderHeaders = () => {
    return (
      <tr>
        {TABLE_COLUMNS.map((column) => (
          <th key={column.accessor}>{column.header}</th>
        ))}
      </tr>
    );
  };

  renderRows = () => {
    const { bingoRounds } = this.props;

    const data = bingoRounds.map((bingoRound) => {
      return {
        bingoRound,
        id: bingoRound.id,
        category: bingoRound.attributes.content.attributes.category || "",
        message: bingoRound.attributes.content.attributes.message,
        answer: bingoRound.attributes.content.attributes.answer,
        draw: (
          <div className="flex-row">
            <p>{bingoRound.attributes.formattedDraw}</p>
            {bingoRound.attributes.additionalMessage && (
              <Tooltip content={bingoRound.attributes.additionalMessage}>
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            )}
          </div>
        ),
        date: moment(bingoRound.attributes.drawAt).format("MM-DD-YY"),
        endDate: moment(bingoRound.attributes.drawAt),
        startDate: moment(bingoRound.attributes.drawAt),
      };
    });

    return data.map((row) => (
      <Row key={row.id} columns={TABLE_COLUMNS} {...row} />
    ));
  };

  render() {
    return (
      <div className="BindoRoundsTable scrollable-table-container">
        <table>
          <thead>{this.renderHeaders()}</thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </div>
    );
  }
}

export default BingoRoundsTable;
