import { action, computed, makeObservable, observable } from "mobx";
import * as companiesSessionsService from "../services/companies/gameSessions";
import * as gameSessionsService from "../services/gameSessions";
import * as arrayUtils from "../utils/array";

const ACTIVE_STATUS = "active";

export class GameSessionsStore {
  @observable gameSessions = [];
  @observable isLoading = true;
  @observable error;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
  }

  @computed get activeGameSession() {
    const { activeCompany } = this.appStore.companiesStore;

    const companySessions = this.findGameSessionsForCompany(activeCompany.id);
    const activeSession = companySessions.find(
      (session) => session.attributes.status === ACTIVE_STATUS
    );

    return activeSession;
  }

  @computed get previousSessions() {
    const { activeCompany } = this.appStore.companiesStore;
    const companySessions = this.findGameSessionsForCompany(activeCompany.id);

    const previousSessions = companySessions.filter(
      (session) => session.attributes.status !== "active"
    );

    return previousSessions;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setGameSessions(gameSessions) {
    this.gameSessions = gameSessions;
  }

  @action
  cacheGameSession(gameSession) {
    const newSessions = [gameSession, ...this.gameSessions];
    const newSessionsUnique = arrayUtils.uniqueOnId(newSessions);

    this.setGameSessions(newSessionsUnique);
  }

  @action
  setError(error) {
    this.error = error;
  }

  @action
  async endCurrentGame() {
    const { companiesStore, token } = this.appStore;

    const companyId = companiesStore.activeCompany.id;

    await gameSessionsService.putGameSession(token, this.activeGameSession.id, {
      finish_game: true,
    });

    await this.retrieveGameSessionsForCompany(companyId);
  }

  @action
  async startNewGame() {
    const { companiesStore, token } = this.appStore;

    const { activeCompany } = companiesStore;

    const response = await companiesSessionsService.postGameSession(
      token,
      activeCompany.id,
      {
        manually_managed: true,
      }
    );

    this.cacheGameSession(response);
  }

  findGameSessionsForCompany = (companyId) => {
    return this.gameSessions.filter(
      (session) =>
        session.attributes.companyId.toString() === companyId.toString()
    );
  };

  @action
  retrieveGameSessionsForCompany = async (companyId) => {
    try {
      const { token } = this.appStore;

      const response = await companiesSessionsService.getGameSessions(
        token,
        companyId
      );
      this.setGameSessions(response.data);
    } catch (err) {
      this.setError(err.message);
    }
  };
}
