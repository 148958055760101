import React from "react";
import PropTypes from "prop-types";
import Tippy from "@tippy.js/react";
import "./Tooltip.scss";

export class Tooltip extends React.Component {
  static defaultProps = {
    delay: 0,
    direction: "top",
    animation: "perspective",
  };

  static propTypes = {
    delay: PropTypes.number,
    children: PropTypes.node.isRequired,
    direction: PropTypes.oneOf(["left", "right", "top", "bottom"]),
    content: PropTypes.node.isRequired,
    animation: PropTypes.oneOf([
      "shift-away",
      "shift-toward",
      "scale",
      "perspective",
    ]),
    visible: PropTypes.bool,
  };

  render() {
    const { animation, children, content, delay, direction, visible } =
      this.props;

    return (
      <div>
        <Tippy
          content={content}
          placement={direction}
          delay={delay}
          animation={animation}
          visible={visible}
        >
          {children}
        </Tippy>
      </div>
    );
  }
}

export default Tooltip;
