import React from "react";
import PropTypes from "prop-types";
import { Button, Switch, TextField } from "../../../controls";
import { WinConditionPattern } from "../WinCondition/WinConditionPattern";

import "./WinConditionInput.scss";

export class WinConditionInput extends React.Component {
  static defaultProps = {
    pattern: [],
  };

  static propTypes = {
    pattern: PropTypes.array.isRequired,
    exact: PropTypes.bool,
    submitText: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    name: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      pattern: props.pattern,
      exact: true,
    };
  }

  onFieldChange = (field, value) => {
    const newState = this.state;

    newState[field] = value;

    this.setState(newState);
  };

  onExactChange = (newValue) => {
    this.onFieldChange("exact", newValue);
  };

  onNameChange = (newValue) => {
    this.onFieldChange("name", newValue);
  };

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { exact, name, pattern } = this.state;

    if (pattern.length > 0) {
      pattern[0]["exact"] = exact;
    }

    onSubmit({
      pattern,
      name,
      exact,
    });
  };

  onCellClick = (x, y, toBeAdded) => {
    const { pattern } = this.state;

    let newPattern = pattern;
    if (toBeAdded) {
      newPattern.push({ col: y, row: x });
    } else {
      newPattern = pattern.filter((item) => item.col !== y || item.row !== x);
    }

    this.onFieldChange("pattern", newPattern);
  };

  render() {
    const { submitText } = this.props;
    const { exact, name, pattern } = this.state;

    return (
      <div className="WinConditionInput">
        <TextField
          placeholder="Pattern Name"
          defaultValue={name}
          onChange={this.onNameChange}
        />
        <Switch
          onChange={this.onExactChange}
          defaultValue={exact}
          label="Cells must be exact positions"
        />
        <WinConditionPattern
          exact={exact}
          pattern={pattern}
          onChange={this.onCellClick}
        />
        <Button onClick={this.onSubmit}>{submitText}</Button>
      </div>
    );
  }
}
