import * as axiosRequests from "./axiosRequests";
import { getEndpoint, requestHeaders } from "../utils/endpoint.js";

const GAME_SESSION_ENDPOINT = (id) =>
  getEndpoint(`api/v1.0/game_sessions/${id}`);

export const putGameSession = async (token, gameSessionId, payload) => {
  const response = await axiosRequests.axiosPut(
    GAME_SESSION_ENDPOINT(gameSessionId),
    {
      game_session: payload,
    },
    requestHeaders(token)
  );

  return response.data;
};
