import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Card.scss";

export class Card extends React.Component {
  static propTypes = {
    card: PropTypes.object,
  };

  renderCellsForColumn = (column) => {
    const { card } = this.props;
    const { previousDraws } = card.attributes;

    return column.map((cell) => {
      const cellClass = classNames("Card__Cell", {
        "--hit": previousDraws.includes(cell) || cell === null,
      });

      return (
        <div className={cellClass} key={cell}>
          {cell}
        </div>
      );
    });
  };

  renderColumns = () => {
    const { card } = this.props;
    return (
      <div className="Card__Columns">
        {card.attributes.numbers.map((column) => (
          <div className="Card__Column" key={column}>
            {this.renderCellsForColumn(column)}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { card } = this.props;
    const { user } = card.attributes;

    return (
      <div className="Card">
        <h2>{user.attributes.fullName}</h2>
        <div className="Card__Container">{this.renderColumns()}</div>
      </div>
    );
  }
}
