import * as axiosRequests from "../axiosRequests";
import { getEndpoint, requestHeaders } from "../../utils/endpoint.js";

const GAME_SESSIONS_BINGO_ROUNDS_ENDPOINT = (gameSessionId) =>
  getEndpoint(`api/v1.0/game_sessions/${gameSessionId}/bingo_rounds`);

export const getBingoRounds = async (token, gameSessionId) => {
  const response = await axiosRequests.axiosGet(
    GAME_SESSIONS_BINGO_ROUNDS_ENDPOINT(gameSessionId),
    {},
    requestHeaders(token)
  );

  return response.data;
};

export const createBingoRound = async (token, gameSessionId, bingoRound) => {
  const payload = {
    bingo_round: bingoRound,
  };

  const response = await axiosRequests.axiosPost(
    GAME_SESSIONS_BINGO_ROUNDS_ENDPOINT(gameSessionId),
    payload,
    requestHeaders(token)
  );

  return response.data;
};
