import React from "react";
import PropTypes from "prop-types";

export class Option extends React.Component {
  static propTypes = {
    option: PropTypes.object,
    onChange: PropTypes.func,
  };

  onClick = (event) => {
    const { onChange, option } = this.props;
    event.preventDefault();

    onChange(option);
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onClick(event);
    }
  };

  render() {
    const { option } = this.props;
    return (
      <div
        className="Option"
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
        role="button"
        tabIndex={0}
      >
        {option.label}
      </div>
    );
  }
}
