import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";

export class DropdownToggle extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    toggleIsOpen: PropTypes.func,
  };

  render() {
    const { children, className, toggleIsOpen } = this.props;

    const toggleClass = classNames("DropdownToggle", className);

    return (
      <div
        className={toggleClass}
        onClick={toggleIsOpen}
        onKeyDown={this.onClick}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }
}

export default DropdownToggle;
