import React from "react";
import { inject, observer } from "mobx-react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../controls";

import "./ContractSelector.scss";

export const ContractSelector = inject((store) => store)(
  observer((props) => {
    const { companiesStore, contractsStore } = props.store;
    const { activeCompany } = companiesStore;

    function refreshContracts() {
      contractsStore.retrieveContracts();
    }

    async function onClick(contractId) {
      await contractsStore.updateContract(contractId);

      window.location.reload();
    }

    function renderOptions() {
      const { contracts } = contractsStore;

      return contracts.map((contract) => (
        <DropdownItem
          onClick={onClick.bind(this, contract.id)}
          key={contract.id}
        >
          {contract.attributes.company.attributes.name}
        </DropdownItem>
      ));
    }

    return (
      <>
        <Dropdown className="ContractSelector" onOpen={refreshContracts}>
          <DropdownToggle>{activeCompany.attributes.name}</DropdownToggle>
          <DropdownMenu>{renderOptions()}</DropdownMenu>
        </Dropdown>
      </>
    );
  })
);
