import React from "react";
import { appStorePropTypes } from "../../propTypes/stores";
import { withRouter } from "../../utils/withRouter";
import { Card } from "./Card/Card";
import { inject, observer } from "mobx-react";

import "./Cards.scss";

@inject("store")
@observer
class Cards extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  async componentDidMount() {
    const { store } = this.props;
    const { cardsStore, companiesStore, gameSessionsStore } = store;

    const companyId = companiesStore.activeCompany.id;

    if (!gameSessionsStore.activeGameSession) {
      await gameSessionsStore.retrieveGameSessionsForCompany(companyId);
    }

    cardsStore.retrieveCardsForGameSession(
      gameSessionsStore.activeGameSession.id
    );
  }

  getCards = () => {
    const { store } = this.props;
    const { cardsStore, gameSessionsStore } = store;
    const { activeGameSession } = gameSessionsStore;

    if (activeGameSession) {
      return cardsStore.getCardsForGameSession(activeGameSession.id);
    } else {
      return [];
    }
  };

  render() {
    const cards = this.getCards();

    return (
      <div className="page-wrap">
        <div className="CardList">
          {cards.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(Cards);
