import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./Text.scss";

export class Text extends React.Component {
  static defaultProps = {
    type: "P",
  };

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf(["H1", "H2", "H3", "P"]),
    style: PropTypes.object,
    children: PropTypes.node,
  };

  renderParagraphTag = (textClass, id, label, style, children) => {
    return (
      <p className={textClass} id={id} label={label} style={{ ...style }}>
        {children}
      </p>
    );
  };

  renderHtmlType = () => {
    const { children, className, id, label, style, type } = this.props;

    const textClass = classNames("Text", type, className);

    let html;
    switch (type) {
      case "H1":
        html = (
          <h1 className={textClass} id={id} label={label} style={{ ...style }}>
            {children}
          </h1>
        );
        break;
      case "H2":
        html = (
          <h2 className={textClass} id={id} label={label} style={{ ...style }}>
            {children}
          </h2>
        );
        break;
      case "H3":
        html = (
          <h3 className={textClass} id={id} label={label} style={{ ...style }}>
            {children}
          </h3>
        );
        break;
      default:
        html = (
          <p className={textClass} id={id} label={label} style={{ ...style }}>
            {children}
          </p>
        );
    }
    return html;
  };

  render() {
    return this.renderHtmlType();
  }
}

export default Text;
