import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../controls/Button/Button";

export class AddContentButton extends React.Component {
  static propTypes = {
    content: PropTypes.object,
    onClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
    };
  }

  onClick = async () => {
    const { content, onClick } = this.props;

    await onClick(content);

    this.setState({ clicked: true });
  };

  render() {
    const { clicked } = this.state;

    if (clicked) {
      return <div>Added!</div>;
    } else {
      return <Button onClick={this.onClick}>Add</Button>;
    }
  }
}

export default AddContentButton;
