import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { Text } from "../Text";

import "./Confirm.scss";

export class Confirm extends React.Component {
  static propTypes = {
    /** Additional content to render in the body */
    children: PropTypes.node,
    /** Additional classNames */
    className: PropTypes.string,
    /** Whether Modal is open or not */
    isOpen: PropTypes.bool,
    /** Callback function to request the closing of Modal */
    onRequestClose: PropTypes.func,
    /** Callback function when confirm Button is clicked */
    onConfirm: PropTypes.func,
    /** If true renders a warning icon */
    severe: PropTypes.bool,
    /** Title of the confirm modal */
    title: PropTypes.string,
    /** The type of the modal, set to small for a smaller modal */
    type: PropTypes.string,
  };

  static defaultProps = {
    title: "Are you sure?",
    description: null,
  };

  onConfirmClick = () => {
    const { onConfirm } = this.props;
    if (onConfirm) {
      onConfirm();
    }
  };

  render() {
    const { children, className, isOpen, onRequestClose, severe, title, type } =
      this.props;

    const confirmClass = classNames("Confirm", type, className);

    return (
      <div data-testid="Confirm">
        <Modal
          className={confirmClass}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
        >
          <div className="Confirm__content">
            {severe && <div data-testid="warningIcon">!</div>}
            <Text type="H1">{title}</Text>
            {children && (
              <div className="Confirm__children" data-testid="confirmChildren">
                {children}
              </div>
            )}
          </div>
          <div className="Confirm__footer">
            <Button data-testid="noButton" type="text" onClick={onRequestClose}>
              Cancel
            </Button>
            <Button
              data-testid="yesButton"
              type="primary"
              onClick={this.onConfirmClick}
            >
              Confirm
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Confirm;
