import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../controls";
import { BingoRoundsModal } from "../Form/BingoRoundsModal";
import EditIcon from "@mui/icons-material/Edit";

export const Row = (props) => {
  const { bingoRound, columns } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderCellsForRow = () => {
    return columns.map((column) => {
      if (column.accessor === "draw") {
        return (
          <td key="draw">
            <p>{props.draw}</p>
          </td>
        );
      } else if (column.accessor === "edit") {
        return (
          <td key="edit">
            <Button
              className="EditButton"
              onClick={() => setIsModalOpen(true)}
              type="text"
            >
              <EditIcon />
            </Button>
          </td>
        );
      }

      return <td key={column.accessor}>{props[column.accessor]}</td>;
    });
  };

  return (
    <tr>
      {renderCellsForRow()}
      {isModalOpen && (
        <BingoRoundsModal
          isOpen={isModalOpen}
          bingoRound={bingoRound}
          onRequestClose={() => setIsModalOpen(false)}
        />
      )}
    </tr>
  );
};

Row.propTypes = {
  bingoRound: PropTypes.shape({
    attributes: PropTypes.object,
    id: PropTypes.string,
  }),
  draw: PropTypes.string,
  date: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
};
