import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Modal, TextField } from "../../../controls";

import "./NewSiteModal.scss";

export const NewSiteModal = inject((store) => store)(
  observer(({ isOpen, onRequestClose, store }) => {
    const [site, setSite] = useState({});

    function updateSite(payload) {
      site[payload.id] = payload.value;

      setSite(site);
    }

    function submitForm() {
      const { companiesStore } = store;
      const { activeCompany } = companiesStore;

      companiesStore.createJobSiteForCompany(activeCompany.id, site);
      onRequestClose();
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="NewSiteModal"
      >
        <h1>Add a Job Site</h1>
        <div className="field">
          <TextField
            className="form-input"
            id="name"
            label="Name"
            onChange={updateSite}
          />
        </div>
        <div className="field">
          <TextField
            className="form-input"
            id="address"
            label="Address"
            onChange={updateSite}
          />
        </div>
        <div className="submit-row">
          <Button onClick={submitForm}>Add Site</Button>
        </div>
      </Modal>
    );
  })
);

export default NewSiteModal;
