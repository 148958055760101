import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./PasswordField.scss";

export class PasswordField extends React.Component {
  static defaultProps = {
    disabled: false,
  };

  static propTypes = {
    /** Additional class names for the component */
    className: PropTypes.string,
    /** Error message to be displayed */
    errorMessage: PropTypes.string,
    /** Callback function for when PasswordField's input is changed */
    onChange: PropTypes.func,
    /** Callback function for when the PasswordField is clicked */
    onClick: PropTypes.func,
    /** Callback function for when the TextField has a keyDown event */
    onKeyDown: PropTypes.func,
    /** Whether the text field's input can be changed */
    disabled: PropTypes.bool,
    /** Id associated with component */
    id: PropTypes.any,
    /** Label associated with component */
    label: PropTypes.string,
    /** Placeholder associated with component */
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  onChange = (event) => {
    const { disabled, id, onChange } = this.props;

    const { value } = event.target;

    this.setState({ value });

    if (!disabled) {
      if (onChange) {
        if (id) {
          onChange({
            id,
            value,
          });
        } else {
          onChange(value);
        }
      }
    }
  };

  onClick = (event) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(event);
    }
  };

  onKeyDown = (event) => {
    const { onKeyDown } = this.props;

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  render() {
    const { className, disabled, errorMessage, id, label, placeholder } =
      this.props;

    const { value } = this.state;

    const passwordFieldClass = classNames(
      "passwordField",
      { "passwordField--disabled": disabled },
      { "passwordField--error": errorMessage },
      className
    );
    return (
      <div className={passwordFieldClass}>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          className="passwordField__input"
          type="password"
          tabIndex={0}
          onChange={this.onChange}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          value={value}
        />
        {errorMessage && (
          <div className="passwordField__message">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default PasswordField;
