import { action, makeObservable, observable } from "mobx";
import * as sessionRoundsService from "../services/gameSessions/bingoRounds";
import * as roundsService from "../services/bingoRounds";
import * as arrayUtils from "../utils/array";

export class BingoRoundsStore {
  @observable bingoRounds = [];
  @observable isLoading = true;
  @observable errorMessage;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setError(errorMessage) {
    this.errorMessage = errorMessage;
  }

  @action
  setBingoRounds(bingoRounds) {
    this.bingoRounds = bingoRounds;
  }

  @action
  retrieveBingoRoundsForGameSession = async (gameSessionId) => {
    try {
      const { token } = this.appStore;

      const response = await sessionRoundsService.getBingoRounds(
        token,
        gameSessionId
      );

      this.cacheBingoRounds(response.data);
    } catch (err) {
      this.setError(err.message);
    }
  };

  @action
  cacheBingoRounds(newBingoRounds) {
    const allRounds = [...newBingoRounds, ...this.bingoRounds];

    const allRoundsUnique = arrayUtils.uniqueOnId(allRounds, "id");

    this.setBingoRounds(allRoundsUnique);
  }

  getBingoRoundsForGameSession = (gameSessionId) => {
    const rounds = this.bingoRounds.filter(
      (bingoRound) =>
        bingoRound.attributes.gameSessionId.toString() ===
        gameSessionId.toString()
    );

    return rounds;
  };

  @action
  createBingoRound = async (gameSessionId, payload) => {
    try {
      const { token } = this.appStore;

      const response = await sessionRoundsService.createBingoRound(
        token,
        gameSessionId,
        payload
      );

      this.cacheBingoRounds(response.data);
    } catch (err) {
      this.setError(err.message);
    }
  };

  @action
  updateBingoRound = async (bingoRoundId, payload) => {
    try {
      const { token } = this.appStore;

      const bingoRound = await roundsService.putBingoRound(
        token,
        bingoRoundId,
        payload
      );

      this.cacheBingoRounds([bingoRound]);
    } catch (err) {
      console.log(err);
      this.setError(err.message);
    }
  };
}
