import React from "react";
import { appStorePropTypes } from "../../../propTypes/stores";
import { inject, observer } from "mobx-react";
import { withRouter } from "../../../utils/withRouter";
import { UsersTable } from "./UsersTable/UsersTable";
import { NewUserForm } from "./NewUserForm/NewUserForm";

import "./Users.scss";

@inject("store")
@observer
class Users extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { store } = this.props;
    const { usersStore } = store;

    usersStore.retrieveUsersAsync();
  }

  setIsModalOpen = (isModalOpen) => {
    this.setState({ isModalOpen });
  };

  render() {
    const { isLoading } = this.props.store.usersStore;

    return (
      <div className="page-wrap">
        <div className="search-filter-add-row">
          <button onClick={() => this.setIsModalOpen(true)}>Add User</button>
        </div>
        <div>
          <NewUserForm
            opened={this.state.isModalOpen}
            setIsModalOpen={this.setIsModalOpen}
          />
        </div>
        {isLoading ? <div>Loading....</div> : <UsersTable />}
      </div>
    );
  }
}

export default withRouter(Users);
