import React from "react";
import classNames from "classnames";
import ReactModal from "react-modal";
import PropTypes from "prop-types";

import "./Modal.scss";

export class Modal extends React.Component {
  static defaultProps = {
    isOpen: false,
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    onRequestClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
    };
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    this.updateModalOpenIfPropChanged(isOpen, prevProps.isOpen);
  }

  onRequestClose = () => {
    const { onRequestClose } = this.props;

    if (onRequestClose) {
      onRequestClose();
    } else {
      this.setState({ isOpen: false });
    }
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onRequestClose();
    }
  };

  updateModalOpenIfPropChanged = (newIsModalOpen, previousIsModalOpen) => {
    if (newIsModalOpen !== previousIsModalOpen) {
      this.setState({ isOpen: newIsModalOpen });
    }
  };

  render() {
    const { children, className } = this.props;
    const { isOpen } = this.state;

    const modalClass = classNames("modal__content", className);

    return (
      <ReactModal
        isOpen={isOpen}
        className={modalClass}
        overlayClassName="modal__overlay"
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        shouldReturnFocusAfterClose
        ariaHideApp={false}
        onRequestClose={this.onRequestClose}
      >
        <span
          className="modal__close"
          onClick={this.onRequestClose}
          onKeyDown={this.onKeyDown}
          role="button"
          tabIndex={0}
        >
          &times;
        </span>
        {children}
      </ReactModal>
    );
  }
}

export default Modal;
