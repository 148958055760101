import { axiosDelete, axiosGet, axiosPost } from "../axiosRequests";
import { getEndpoint, requestHeaders } from "../../utils/endpoint.js";

const COMPANY_JOB_SITES_ENDPOINT = (companyId) =>
  getEndpoint(`api/v1.0/companies/${companyId}/job_sites`);
const COMPANY_JOB_SITE_ENDPOINT = (companyId, jobSiteId) =>
  getEndpoint(`api/v1.0/companies/${companyId}/job_sites/${jobSiteId}`);

export const getJobSites = async (token, companyId) => {
  const response = await axiosGet(
    COMPANY_JOB_SITES_ENDPOINT(companyId),
    {},
    requestHeaders(token)
  );

  return response;
};

export const postJobSite = async (token, companyId, jobSite) => {
  const response = await axiosPost(
    COMPANY_JOB_SITES_ENDPOINT(companyId),
    {
      job_site: jobSite,
    },
    requestHeaders(token)
  );

  return response;
};

export const deleteJobSite = async (token, companyId, jobSiteId) => {
  const response = await axiosDelete(
    COMPANY_JOB_SITE_ENDPOINT(companyId, jobSiteId),
    {},
    requestHeaders(token)
  );

  return response;
};
