import React from "react";
import { inject, observer } from "mobx-react";
import { Modal, Text } from "../../../controls";
import { BingoRoundsForm } from "./BingoRoundsForm";

export const BingoRoundsModal = inject((store) => store)(
  observer((props) => {
    const { bingoRound, isOpen, onRequestClose } = props;

    return (
      <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
        <Text type="H1" className="modal__title">
          Edit Round
        </Text>
        <BingoRoundsForm
          bingoRound={bingoRound}
          onSubmit={onRequestClose}
          onCancel={onRequestClose}
        />
      </Modal>
    );
  })
);
