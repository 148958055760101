import axios from "axios";

axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true;

export async function axiosGet(endpoint, params = {}, headers = {}) {
  const response = await axios.get(endpoint, {
    ...params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
  });

  return {
    headers: response.headers,
    data: response.data,
  };
}

export async function axiosPut(endpoint, payload = {}, headers = {}) {
  const response = await axios.put(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
  });

  return response;
}

export async function axiosPost(endpoint, payload = {}, headers = {}) {
  const response = await axios.post(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
  });

  return response;
}

export async function axiosDelete(endpoint, data = {}, additionalHeaders = {}) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    ...additionalHeaders,
  };

  const response = await axios.delete(endpoint, {
    data,
    headers,
  });

  return response;
}
