import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { AppProvider, BannerProvider } from "./context";
import { NavigationBar } from "./layout/Navigation/NavigationBar";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AdminPanel from "./pages/Company/AdminPanel";
import EditCompany from "./pages/Company/EditCompany";
import Users from "./pages/Company/Users/Users";
import { Login } from "./pages/Login";
import { SignUp } from "./pages/SignUp";
import Home from "./pages/Home/Home";
import AddMessage from "./pages/CurrentGame/AddMessage";
import PastGames from "./pages/PastGames/PastGames";
import JobSites from "./pages/JobSites/JobSites";
import MessageDatabase from "./pages/MessageDatabase/MessageDatabase";
import { AppDownload } from "./pages/AppDownload";
import { PrivateRoute } from "./PrivateRoute";
import { AdminRoute } from "./AdminRoute";
import { WinConditions } from "./pages/WinConditions/WinConditions";
import Cards from "./pages/Cards/Cards";
import { NoMatch } from "./errors/NoMatch";
import { Banner } from "./layout/Navigation/Banner.js";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <BannerProvider>
          <Router>
            <AppProvider>
              <div className="nav-banner-wrap">
                <NavigationBar />
                <div className="banner-content-wrap">
                  <Banner />
                  <Routes>
                    <Route path="/app_download" element={<AppDownload />} />
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <Home />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/pastGames"
                      element={
                        <PrivateRoute>
                          <PastGames />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/cards" element={<Cards />} />
                    <Route path="/sign_in" element={<Login />} />
                    <Route path="/sign_up" element={<SignUp />} />
                    <Route path="/companies/:id" element={<AdminRoute />}>
                      <Route
                        path="current-game/add-messages"
                        element={<AddMessage />}
                      />
                      <Route path="winConditions" element={<WinConditions />} />
                      <Route path="messages" element={<MessageDatabase />} />
                      <Route path="" element={<AdminPanel />} />
                      <Route
                        path="/companies/:id/edit"
                        element={<EditCompany />}
                      />
                      <Route path="/companies/:id/users" element={<Users />} />
                      <Route
                        path="/companies/:id/jobSites"
                        element={<JobSites />}
                      />
                      {/* <Route path="/companies/:id/edit" element={<PrivateRoute><EditCompany /></PrivateRoute>} /> comment out here later */}
                    </Route>
                    <Route path="*" element={<NoMatch />} />
                  </Routes>
                </div>
              </div>
            </AppProvider>
          </Router>
        </BannerProvider>
      </DndProvider>
    </div>
  );
}

export default App;
