import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Modal, Text } from "../../../controls";
import { WinConditionInput } from "../WinConditionInput/WinConditionInput";

import "./NewWinConditionModal.scss";

@inject("store")
@observer
export class NewWinConditionModal extends React.Component {
  static propTypes = {
    store: PropTypes.object,
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      winCondition: {},
    };
  }

  onSubmit = async (payload) => {
    const { onRequestClose, store } = this.props;
    const { winConditionsStore } = store;

    try {
      await winConditionsStore.createWinConditionOnActiveCompany(payload);
      onRequestClose();
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { isOpen, onRequestClose } = this.props;
    const { winCondition } = this.state;

    return (
      <Modal
        className="NewWinConditionModal"
        isOpen={isOpen}
        onRequestClose={onRequestClose}
      >
        <Text type="H1">New Win Pattern</Text>
        <WinConditionInput
          winCondition={winCondition}
          submitText="Add"
          onSubmit={this.onSubmit}
        />
      </Modal>
    );
  }
}
