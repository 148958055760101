import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { DropdownToggle } from "./DropdownToggle";
import { DropdownMenu } from "./DropdownMenu";

import "./Dropdown.scss";

export class Dropdown extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onOpen: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.fieldRef = React.createRef();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  toggleIsOpen = (event) => {
    const { onOpen } = this.props;
    event.stopPropagation();
    event.preventDefault();

    const { isOpen } = this.state;
    this.setIsOpen(!isOpen);

    if (onOpen) {
      onOpen();
    }
  };

  handleClickOutside = (event) => {
    if (this.fieldRef && !this.fieldRef.current.contains(event.target)) {
      this.setIsOpen(false);
    }
  };

  setIsOpen = (newIsOpen) => {
    if (newIsOpen) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }

    this.setState({ isOpen: newIsOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { children, className } = this.props;

    const dropdownToggle = React.Children.map(children, (child) => {
      if (child.type === DropdownToggle) {
        return React.cloneElement(child, { toggleIsOpen: this.toggleIsOpen });
      }
      return null;
    });

    const dropdownMenu = React.Children.map(children, (child) => {
      if (child.type === DropdownMenu) {
        return React.cloneElement(child, { isOpen, setIsOpen: this.setIsOpen });
      }
      return null;
    });

    const dropdownClass = classnames("Dropdown", className);

    return (
      <div ref={this.fieldRef} className={dropdownClass}>
        {dropdownToggle}
        {dropdownMenu}
      </div>
    );
  }
}

export default Dropdown;
