import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";

export class WinConditionCell extends React.Component {
  static propTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    onClick: PropTypes.func,
    exact: PropTypes.bool,
    active: PropTypes.bool,
  };

  onClick = () => {
    const { onClick, x, y } = this.props;

    if (onClick) {
      onClick(x, y);
    }
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.onClick();
    }
  };

  render() {
    const { active, exact, onClick, x, y } = this.props;

    const cellClassName = classNames("WinCondition__Cell", {
      "Cell--relative": !exact,
      "Cell--active": active,
      "--clickable": !!onClick,
    });

    return (
      <div
        key={`${y}-${x}`}
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
        className={cellClassName}
        role="button"
        tabIndex={0}
      ></div>
    );
  }
}
