export function uniqueOnId(array, idField) {
  let arrayCopy = array.concat();

  for (let i = 0; i < arrayCopy.length; ++i) {
    for (let j = i + 1; j < arrayCopy.length; ++j) {
      if (arrayCopy[i][idField] === arrayCopy[j][idField])
        arrayCopy.splice(j--, 1);
    }
  }

  return arrayCopy;
}
