import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Button, Modal, Select, TextField } from "../../../controls";
import { MultipleChoiceInput } from "./MultipleChoiceInput";
import "./NewContentForm.scss";

const TYPE_OPTIONS = [
  {
    value: "Content",
    label: "Message",
  },
  {
    value: "Question::TrueFalse",
    label: "True/False",
  },
  {
    value: "Question::Multichoice",
    label: "Multiple Choice",
  },
];

export const NewContentForm = inject((store) => store)(
  observer((props) => {
    const [content, setContent] = useState({});
    const { closeModal, isOpen, store } = props;

    const { companiesStore, contentsStore } = store;
    const { activeCompany } = companiesStore;

    function updateContent(payload) {
      const newContent = structuredClone(content);
      newContent[payload.id] = payload.value;

      if (payload.id === "type" && newContent.custom_fields) {
        const fieldsCopy = newContent.custom_fields;

        newContent.custom_fields = {
          value: fieldsCopy.value,
        };
      }

      setContent(newContent);
    }

    function updateContentCustomFields(payload) {
      const newContent = structuredClone(content);

      if (!newContent.custom_fields) {
        newContent.custom_fields = {};
      }

      newContent.custom_fields[payload.id] = payload.value;

      setContent(newContent);
    }

    async function onSubmit() {
      if (content.type === "Question::Multichoice") {
        content.custom_fields.choices.push(content.custom_fields.value);
      }

      await contentsStore.createContentForCompany(activeCompany.id, content);

      closeModal();
    }

    function renderAdditionalFields() {
      switch (content.type) {
        case "Question::Multichoice":
          return (
            <>
              <div className="field">
                <TextField
                  className="form-input"
                  id="value"
                  label="Answer"
                  onChange={updateContentCustomFields}
                />
              </div>
              <div className="field">
                <MultipleChoiceInput onChange={updateContentCustomFields} />
              </div>
            </>
          );
        case "Question::TrueFalse":
          return (
            <>
              <div className="field">
                <Select
                  className="form-input"
                  id="value"
                  label="Answer"
                  options={[
                    { value: "true", label: "True" },
                    { value: "false", label: "False" },
                  ]}
                  onChange={updateContentCustomFields}
                />
              </div>
              <div className="field"></div>
            </>
          );
      }
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="NewContentForm"
      >
        <h1>Add a new prompt</h1>
        <div className="field">
          <TextField
            className="form-input"
            id="message"
            label="Message"
            onChange={updateContent}
          />
        </div>
        <div className="field">
          <TextField
            className="form-input"
            id="category"
            label="Category"
            onChange={updateContent}
          />
        </div>
        <div className="field">
          <Select
            className="form-input"
            selectedOption={TYPE_OPTIONS[0]}
            id="type"
            label="Type"
            onChange={updateContent}
            options={TYPE_OPTIONS}
          />
        </div>
        {renderAdditionalFields()}
        <div className="submit-row">
          <Button onClick={onSubmit}>Add</Button>
        </div>
      </Modal>
    );
  })
);
