import React from "react";
import { inject, observer } from "mobx-react";
import { Table } from "../../../components/Table/Table";

const TABLE_COLUMNS = [
  {
    header: "ID",
    accessor: "id",
  },
  {
    header: "JOBSITE",
    accessor: "name",
  },
  {
    header: "ADDRESS",
    accessor: "address",
  },
];

export const SitesTable = inject((store) => store)(
  observer(({ store }) => {
    function getDataForTable() {
      const { companiesStore } = store;
      const { activeCompany } = companiesStore;

      const { jobSites } = activeCompany.attributes;

      return jobSites.map((site) => {
        return {
          id: site.id,
          name: site.attributes.name,
          address: site.attributes.address,
        };
      });
    }

    const data = getDataForTable();

    return (
      <div className="scrollable-table-container">
        <Table headers={TABLE_COLUMNS} data={data} />
      </div>
    );
  })
);
