import React from "react";
import PropTypes from "prop-types";

export class ErrorBoundary extends React.Component {
  static propTypes = { children: PropTypes.node };

  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <div>{error}</div>;
    }
    return children;
  }
}

export default ErrorBoundary;
