import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../controls";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { WinConditionPattern } from "./WinConditionPattern";
import { winConditionPropTypes } from "../../../propTypes/models";

import "./WinCondition.scss";

export const WinCondition = inject((store) => store)(
  observer((props) => {
    const { store, winCondition } = props;
    const { winConditionsStore } = store;
    const { exact, name, pattern } = winCondition.attributes;

    function renderPattern() {
      return (
        <WinConditionPattern
          exact={exact}
          pattern={pattern}
          key={winCondition.id}
        />
      );
    }

    function deleteWinCondition() {
      winConditionsStore.deleteWinCondition(winCondition.id);
    }

    return (
      <div className="WinCondition">
        <Dropdown className="WinCondition__Edit">
          <DropdownToggle>
            <MoreVertIcon />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={deleteWinCondition}>Delete</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <h2 className="WinCondition__Identifier">
          {name || `Condition #${winCondition.id}`}
        </h2>
        {renderPattern()}
      </div>
    );
  })
);

WinCondition.propTypes = {
  winCondition: winConditionPropTypes,
  onCellClick: PropTypes.func,
};
