import React from "react";
import { inject, observer } from "mobx-react";
import { Button, Text } from "../../controls";
import AddIcon from "@mui/icons-material/Add";
import { WinCondition } from "./WinCondition/WinCondition";
import { NewWinConditionModal } from "./NewWinConditionModal/NewWinConditionModal";
import { appStorePropTypes } from "../../propTypes/stores";

import "./WinConditions.scss";

@inject("store")
@observer
export class WinConditions extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { store } = this.props;
    const { companiesStore, winConditionsStore } = store;
    const { activeCompany } = companiesStore;

    winConditionsStore.retrieveWinConditionsForCompany(activeCompany.id);
  }

  renderWinConditions = () => {
    const { store } = this.props;
    const { winConditionsStore } = store;
    const { winConditions } = winConditionsStore;

    return winConditions.map((winCondition) => {
      return <WinCondition key={winCondition.id} winCondition={winCondition} />;
    });
  };

  setIsModalOpen = (isModalOpen) => {
    this.setState({ isModalOpen });
  };

  openModal = () => {
    this.setIsModalOpen(true);
  };

  renderNewWinCondition = () => {
    const { isModalOpen } = this.state;

    return (
      <>
        <Button
          type="text"
          className="NewWinConditionButton"
          onClick={this.openModal}
        >
          <AddIcon />
          <Text type="H2">New Win Pattern</Text>
        </Button>
        <NewWinConditionModal
          isOpen={isModalOpen}
          onRequestClose={() => this.setIsModalOpen(false)}
        />
      </>
    );
  };

  render() {
    return (
      <div className="WinConditions">
        <div className="WinConditions__list">
          {this.renderWinConditions()}
          {this.renderNewWinCondition()}
        </div>
      </div>
    );
  }
}
