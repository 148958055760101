import React from "react";
import PropTypes from "prop-types";
import { appStorePropTypes } from "../../propTypes/stores";
import { withRouter } from "../../utils/withRouter";
import { inject, observer } from "mobx-react";
import CurrentGame from "../CurrentGame/CurrentGame";
import { Button } from "../../controls";
import "./Home.scss";

@withRouter
@inject("store")
@observer
class Home extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal_active: false,
    };
  }

  componentDidMount() {
    const { store } = this.props;
    const { companiesStore, gameSessionsStore } = store;
    const { activeCompany } = companiesStore;

    gameSessionsStore.retrieveGameSessionsForCompany(activeCompany.id);
  }

  goToPastGames = () => {
    const { router } = this.props;

    router.navigate("/pastGames");
  };

  startGame = () => {
    const { store } = this.props;
    const { gameSessionsStore } = store;

    gameSessionsStore.startNewGame();
  };

  render() {
    const { store } = this.props;
    const { gameSessionsStore } = store;
    const { activeGameSession } = gameSessionsStore;

    return (
      <div className="page-wrap">
        {activeGameSession ? (
          <CurrentGame />
        ) : (
          <div className="NoSession__Options">
            <Button onClick={this.startGame}>Start New Game</Button>
            <Button onClick={this.goToPastGames}>Review Past Results</Button>
          </div>
        )}
      </div>
    );
  }
}

export default Home;
