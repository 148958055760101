import React, { useState } from "react";
import moment from "moment";
import { inject, observer } from "mobx-react";
import { Button, DateField, TextField } from "../../../controls";

import "./BingoRoundsForm.scss";

export const BingoRoundsForm = inject((store) => store)(
  observer((props) => {
    const { bingoRound, onCancel, onSubmit, store } = props;

    const [payload, setPayload] = useState({});

    const onFormSubmit = async () => {
      const { bingoRoundsStore } = store;

      await bingoRoundsStore.updateBingoRound(bingoRound.id, payload);

      onSubmit && onSubmit();
    };

    const onFieldChange = (newValue) => {
      const newPayload = { ...payload };

      newPayload[newValue.id] = newValue.value;

      setPayload(newPayload);
    };

    const drawAtInitialValue = moment(bingoRound.attributes.drawAt).format(
      "YYYY-MM-DD"
    );

    return (
      <div className="BingoRoundsForm">
        <TextField
          id="draw"
          defaultValue={bingoRound.attributes.draw}
          onChange={onFieldChange}
          label="Draw"
        />
        <DateField
          id="draw_at"
          onChange={onFieldChange}
          defaultValue={drawAtInitialValue}
          label="Date"
        />
        <TextField
          id="additional_message"
          onChange={onFieldChange}
          defaultValue={bingoRound.attributes.additionalMessage}
          label="Additional Message"
        />
        <div className="flex-row end">
          {onCancel && (
            <Button type="text" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button type="primary" onClick={onFormSubmit}>
            Submit
          </Button>
        </div>
      </div>
    );
  })
);
