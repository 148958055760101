import React, { Component } from "react";
import AuthLoader from "../layout/AuthLoader/AuthLoader";
import { Provider } from "mobx-react";
import { bannerStore } from "./BannerProvider";
import { AppStore } from "../stores/AppStore";
import PropTypes from "prop-types";

const appStore = new AppStore(bannerStore);

export class AppProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    const { children } = this.props;

    return (
      <Provider store={appStore}>
        <AuthLoader>{children}</AuthLoader>
      </Provider>
    );
  }
}
