import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { TextField } from "../../../controls";

import "./MultipleChoiceInput.scss";

const ID_INDEX_MAP = {
  choice1: 0,
  choice2: 1,
  choice3: 2,
};

export const MultipleChoiceInput = (props) => {
  const [choices, setChoices] = useState(["", "", ""]);

  function updateChoices(payload) {
    const newChoices = structuredClone(choices);

    const index = ID_INDEX_MAP[payload.id];

    newChoices[index] = payload.value;

    setChoices(newChoices);

    props.onChange({ id: "choices", value: newChoices });
  }

  return (
    <div className="MultipleChoiceInput">
      <label htmlFor="choice1">Choices</label>
      <TextField id="choice1" placeholder="Choice 1" onChange={updateChoices} />
      <TextField id="choice2" placeholder="Choice 2" onChange={updateChoices} />
      <TextField id="choice3" placeholder="Choice 3" onChange={updateChoices} />
    </div>
  );
};

MultipleChoiceInput.propTypes = {
  onChange: PropTypes.func,
};
