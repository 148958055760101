import { axiosDelete, axiosGet, axiosPost } from "./axiosRequests";
import { getEndpoint, requestHeaders } from "../utils/endpoint.js";

const CURRENT_USER_ENDPOINT = getEndpoint("api/v1.0/users/me");

const SIGN_IN_ENDPOINT = getEndpoint("api/v1.0/users/sign_in");
const SIGN_OUT_ENDPOINT = getEndpoint("api/v1.0/users/sign_out");

export const getMe = async (token) => {
  const response = await axiosGet(
    CURRENT_USER_ENDPOINT,
    {},
    requestHeaders(token)
  );

  return response;
};

export const signIn = async (email, password) => {
  const response = await axiosPost(SIGN_IN_ENDPOINT, {
    user: {
      email,
      password,
    },
  });

  return response.data;
};

export const signOut = async (token) => {
  const response = await axiosDelete(
    SIGN_OUT_ENDPOINT,
    {},
    requestHeaders(token)
  );

  return response;
};
