import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as Checkmark } from "../../assets/icons/checkmark.svg";
import { ReactComponent as Checkbox } from "../../assets/icons/checkbox.svg";
import "./CheckboxField.scss";

const ENTER_KEY = "Enter";

export class CheckboxField extends React.Component {
  static defaultProps = {
    value: "",
    isChecked: false,
  };

  static propTypes = {
    /** Additional class names for the component */
    className: PropTypes.string,
    /** Id associated with component */
    id: PropTypes.any,
    /** Default state for CheckboxField */
    isChecked: PropTypes.bool,
    /** Label associated with component */
    label: PropTypes.string,
    /** Callback function when clicked */
    onChange: PropTypes.func,
    /** Value associated with component */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    /** Disable state for CheckboxField */
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { isChecked } = props;

    this.state = {
      isChecked,
    };
  }

  componentDidUpdate(prevProps) {
    const { isChecked } = this.props;
    if (isChecked !== prevProps.isChecked) {
      this.setIsChecked(isChecked);
    }
  }

  onKeyDownHandler = (event) => {
    const { id, onChange, value } = this.props;
    const { isChecked } = this.state;
    const newIsChecked = !isChecked;

    if (event.key === ENTER_KEY) {
      this.setState({ isChecked: newIsChecked });
      if (onChange) {
        return onChange({
          id,
          isChecked: newIsChecked,
          value,
        });
      }
      return null;
    }
    return null;
  };

  setIsChecked = (isChecked) => {
    this.setState({ isChecked });
  };

  onChange = () => {
    const { disabled, id, onChange, value } = this.props;
    const { isChecked } = this.state;

    if (!disabled) {
      const newIsChecked = !isChecked;

      this.setState({ isChecked: newIsChecked });

      if (onChange) {
        return onChange({
          id,
          isChecked: newIsChecked,
          value,
        });
      }
    }
    return value;
  };

  render() {
    const { isChecked } = this.state;
    const { className, disabled, id, label } = this.props;

    const fieldClass = classNames(
      "checkbox-field-wrapper",
      {
        checkbox__disabled: disabled,
        "--checked": isChecked,
      },
      className
    );

    return (
      <div
        id={id}
        className={fieldClass}
        onClick={this.onChange}
        onKeyDown={this.onKeyDownHandler}
        role="checkbox"
        tabIndex={0}
        aria-checked={isChecked}
      >
        {isChecked && !disabled ? <Checkmark /> : <Checkbox />}
        {label !== null ? <label htmlFor={id}>{label}</label> : null}
      </div>
    );
  }
}

export default CheckboxField;
