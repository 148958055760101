import * as axiosRequests from "../axiosRequests";
import { getEndpoint, requestHeaders } from "../../utils/endpoint.js";

const GAME_SESSIONS_CARDS_ENDPOINT = (gameSessionId) =>
  getEndpoint(`api/v1.0/game_sessions/${gameSessionId}/cards`);

export const getCards = async (token, gameSessionId) => {
  const response = await axiosRequests.axiosGet(
    GAME_SESSIONS_CARDS_ENDPOINT(gameSessionId),
    {},
    requestHeaders(token)
  );

  return response.data;
};
