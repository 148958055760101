import React from "react";
import { inject, observer } from "mobx-react";
import { Navigate, Outlet } from "react-router-dom";
import { appStorePropTypes } from "./propTypes/stores";

@inject("store")
@observer
export class AdminRoute extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  render() {
    const { store } = this.props;
    const { currentUser } = store;

    let isAdmin = false;
    if (currentUser && currentUser.attributes.type === "Manager") {
      isAdmin = true;
    }

    return <div>{isAdmin ? <Outlet /> : <Navigate to="/app_download" />}</div>;
  }
}
