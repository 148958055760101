import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Container.scss";

export class Container extends React.Component {
  static defaultProps = {
    style: {},
  };

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.object,
  };

  render() {
    const { children, className, height, style, width } = this.props;

    const containerClass = classNames("Container", className);

    if (height) {
      style.height = height;
    }

    if (width) {
      style.width = width;
    }

    return (
      <div className={containerClass} style={style}>
        {children}
      </div>
    );
  }
}
