import * as axiosRequests from "../axiosRequests";
import { getEndpoint, requestHeaders } from "../../utils/endpoint.js";

const COMPANIES_WIN_CONDITIONS_ENDPOINT = (companyId) =>
  getEndpoint(`api/v1.0/companies/${companyId}/win_conditions`);

const COMPANIES_WIN_CONDITION_ENDPOINT = (companyId, winConditionId) =>
  getEndpoint(
    `api/v1.0/companies/${companyId}/win_conditions/${winConditionId}`
  );

export const getWinConditions = async (token, companyId) => {
  const response = await axiosRequests.axiosGet(
    COMPANIES_WIN_CONDITIONS_ENDPOINT(companyId),
    {},
    requestHeaders(token)
  );

  return response.data;
};

export const createWinCondition = async (token, companyId, winCondition) => {
  const payload = {
    win_condition: winCondition,
  };

  const response = await axiosRequests.axiosPost(
    COMPANIES_WIN_CONDITIONS_ENDPOINT(companyId),
    payload,
    requestHeaders(token)
  );

  return response.data;
};

export const deleteWinCondition = async (token, companyId, winConditionId) => {
  const response = await axiosRequests.axiosDelete(
    COMPANIES_WIN_CONDITION_ENDPOINT(companyId, winConditionId),
    {},
    requestHeaders(token)
  );

  return response.data;
};
