import React from "react";
import PropTypes from "prop-types";
import { appStorePropTypes } from "../../propTypes/stores";
import { inject, observer } from "mobx-react";

@inject("store")
@observer
class AuthLoader extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    store: appStorePropTypes,
  };
  render() {
    const { children, store } = this.props;
    const { isLoading } = store;

    return <div>{isLoading ? <div></div> : <div>{children}</div>}</div>;
  }
}

export default AuthLoader;
