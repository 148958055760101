import React from "react";
import { inject, observer } from "mobx-react";
import { Button } from "../../controls";
import { withRouter } from "../../utils/withRouter";
import { appStorePropTypes } from "../../propTypes/stores";
import { FullContentTable } from "./FullContentTable";
import { NewContentForm } from "./NewContentForm/NewContentForm";

import "./MessageDatabase.scss";

@inject("store")
@observer
export class MessageDatabase extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
    };
  }

  componentDidMount() {
    const { store } = this.props;
    const { companiesStore, contentsStore } = store;
    const { activeCompany } = companiesStore;

    contentsStore.retrieveContentsForCompany(activeCompany.id);
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { isModalOpen } = this.state;

    return (
      <div className="page-wrap MessageDatabase">
        <div className="search-filter-add-row">
          <Button onClick={this.openModal}>New Prompt</Button>
        </div>
        {isModalOpen && (
          <NewContentForm isOpen={isModalOpen} closeModal={this.closeModal} />
        )}
        <div className="scrollable-table-container">
          <FullContentTable />
        </div>
      </div>
    );
  }
}

export default withRouter(MessageDatabase);
