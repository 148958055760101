import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ENTER_KEY = "Enter";

export class DropdownItem extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  onClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  onKeyDownHandler = (event) => {
    const { onClick } = this.props;
    if (onClick && event.key === ENTER_KEY) {
      this.onClick();
    }
  };

  render() {
    const { children, className } = this.props;

    const dropdownItemClass = classNames(className, "DropdownItem");
    return (
      <div
        className={dropdownItemClass}
        onClick={this.onClick}
        onKeyDown={this.onKeyDownHandler}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }
}

export default DropdownItem;
