import React from "react";
import { inject, observer } from "mobx-react";
import { UserOptions } from "./UserOptions/UserOptions";
import { appStorePropTypes } from "../../propTypes/stores";
import { Link } from "react-router-dom";
import "./Banner.scss";

@inject("store")
@observer
class Banner extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  renderAuthenticatedBanner = () => {
    const { store } = this.props;
    const { companiesStore } = store;
    const { activeCompany } = companiesStore;

    const { logoUrl, name } = activeCompany.attributes;

    return (
      <>
        <Link className="App-navbutton" to="/">
          {logoUrl ? (
            <img src={logoUrl} className="App-logo" alt="logo" />
          ) : (
            <h1 className="Banner__name">{name}</h1>
          )}
        </Link>
        <div className="App-options">
          <UserOptions />
        </div>
      </>
    );
  };

  render() {
    const { store } = this.props;
    const { isAuthenticated } = store;

    if (isAuthenticated) {
      return (
        <header className="App-header">
          {this.renderAuthenticatedBanner()}
        </header>
      );
    }

    return <></>;
  }
}

export { Banner };

export default Banner;
