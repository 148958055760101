import * as axiosRequests from "../axiosRequests";
import { getEndpoint, requestHeaders } from "../../utils/endpoint.js";

const COMPANIES_GAME_SESSIONS_ENDPOINT = (companyId) =>
  getEndpoint(`api/v1.0/companies/${companyId}/game_sessions`);

export const getGameSessions = async (token, companyId) => {
  const response = await axiosRequests.axiosGet(
    COMPANIES_GAME_SESSIONS_ENDPOINT(companyId),
    {},
    requestHeaders(token)
  );

  return response.data;
};

export const postGameSession = async (token, companyId, gameSession) => {
  const response = await axiosRequests.axiosPost(
    COMPANIES_GAME_SESSIONS_ENDPOINT(companyId),
    {
      game_session: gameSession,
    },
    requestHeaders(token)
  );

  return response.data;
};
