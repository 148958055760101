import React from "react";
import { PropTypes } from "prop-types";
import { appStorePropTypes } from "./propTypes/stores";
import { inject, observer } from "mobx-react";
import { hasWebAccess } from "./utils/permissions";
import { Navigate } from "react-router-dom";

@inject("store")
@observer
export class PrivateRoute extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
    children: PropTypes.node,
  };
  render() {
    const { children, store } = this.props;
    const { currentUser, isAuthenticated } = store;

    if (isAuthenticated) {
      if (hasWebAccess(currentUser)) {
        return <>{children}</>;
      } else {
        return <Navigate to="/app_download" />;
      }
    } else {
      return <Navigate to="/sign_in" />;
    }
  }
}
