import React from "react";
import { inject, observer } from "mobx-react";
import { Button, Confirm, Text } from "../../controls";
import { useNavigate } from "react-router-dom";

export const EndGame = inject((store) => store)(
  observer((props) => {
    const navigate = useNavigate();
    const [confirmIsOpen, setConfirmIsOpen] = React.useState(false);
    async function endGame() {
      const { store } = props;
      const { gameSessionsStore } = store;

      try {
        await gameSessionsStore.endCurrentGame();
        navigate("pastGames");
      } catch (err) {
        console.log(err);
      }
    }

    function openConfirm() {
      setConfirmIsOpen(true);
    }

    function closeConfirm() {
      setConfirmIsOpen(false);
    }

    return (
      <>
        <Button type="hazard" onClick={openConfirm}>
          End Game
        </Button>
        <Confirm
          onConfirm={endGame}
          onRequestClose={closeConfirm}
          isOpen={confirmIsOpen}
          title="End Game"
        >
          <Text type="H3">Are you sure you wish to end the game?</Text>
        </Confirm>
      </>
    );
  })
);
