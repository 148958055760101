import React from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Option } from "./Option";

import "./Select.scss";

export class Select extends React.Component {
  static defaultProps = {
    options: [],
  };

  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    selectedOption: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedOption: props.selectedOption,
    };

    this.fieldRef = React.createRef();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  toggleIsOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { isOpen } = this.state;
    this.setIsOpen(!isOpen);
  };

  onChange = (selectedOption) => {
    const { id, onChange } = this.props;

    this.setState({ selectedOption });

    if (onChange) {
      if (id) {
        onChange({ id, selectedOption, value: selectedOption.value });
      } else {
        onChange({ selectedOption, value: selectedOption.value });
      }

      this.setIsOpen(false);
    }
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.toggleIsOpen(event);
    }
  };

  handleClickOutside = (event) => {
    if (this.fieldRef && !this.fieldRef.current.contains(event.target)) {
      this.setIsOpen(false);
    }
  };

  setIsOpen = (newIsOpen) => {
    if (newIsOpen) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }

    this.setState({ isOpen: newIsOpen });
  };

  renderOptions = () => {
    const { options } = this.props;

    return (
      <div className="Select__Menu">
        {options.map((option) => {
          return (
            <Option
              key={option.value}
              onChange={this.onChange}
              option={option}
            />
          );
        })}
      </div>
    );
  };

  renderChevron = () => {
    const { isOpen } = this.state;

    if (isOpen) {
      return <ExpandLessIcon />;
    } else {
      return <ExpandMoreIcon />;
    }
  };

  renderVisibleValue = () => {
    const { placeholder } = this.props;
    const { selectedOption } = this.state;
    let visibleValue;

    if (selectedOption) {
      visibleValue = <p className="Selected">{selectedOption.label}</p>;
    } else {
      visibleValue = <p className="Placeholder">{placeholder}</p>;
    }

    return (
      <div
        className="Selector"
        onClick={this.toggleIsOpen}
        onKeyDown={this.onKeyDown}
        role="button"
        tabIndex={0}
      >
        {visibleValue}
        {this.renderChevron()}
      </div>
    );
  };

  render() {
    const { isOpen, selectedOption } = this.state;
    const { id, label } = this.props;

    const value = selectedOption ? selectedOption.value : "";

    return (
      <div ref={this.fieldRef} className="Select">
        {label && <label htmlFor={id}>{label}</label>}
        {this.renderVisibleValue()}
        <input type="hidden" value={value} id={id} />
        {isOpen && this.renderOptions()}
      </div>
    );
  }
}

export default Select;
