import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Button,
  CheckboxField,
  Modal,
  PasswordField,
  Select,
  TextField,
} from "../../../../controls";
import "./NewUserForm.scss";

export const NewUserForm = inject((store) => store)(
  observer((props) => {
    const [user, setUser] = useState({});

    const { setIsModalOpen, store } = props;
    const { companiesStore, usersStore } = store;
    const { activeCompany } = companiesStore;
    const [showError, setShowError] = useState(false);

    function updateUser(payload) {
      const newUser = structuredClone(user);
      newUser[payload.id] = payload.value;

      setUser(newUser);
    }

    function updateUserType(payload) {
      const newUser = structuredClone(user);

      if (payload.isChecked) {
        newUser.type = "Manager";
      } else {
        newUser.type = "User";
      }

      setUser(newUser);
    }

    function closeModal() {
      setIsModalOpen(false);
      setShowError(false);
    }

    async function submitHandler(event) {
      event.preventDefault();

      try {
        await usersStore.createUser(user);
        closeModal();
      } catch (err) {
        setShowError(true);
      }
    }

    const isOpen = props.opened;

    const jobSiteOptions = activeCompany.attributes.jobSites.map((jobSite) => {
      return {
        value: jobSite.id,
        label: jobSite.attributes.name,
      };
    });

    const supervisorOptions = usersStore.supervisors.map((supervisor) => {
      return {
        value: supervisor.id,
        label: supervisor.attributes.fullName,
      };
    });

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="NewUserForm"
      >
        <div className="modal-content">
          <form>
            <h1>Add a new user</h1>
            <div className="field checkbox-field">
              {/* <label htmlFor="type">Supervisor</label> */}
              <CheckboxField
                className="form-input"
                id="type"
                label=" Admin? (grant access to the web dashboard)"
                onChange={updateUserType}
              />
            </div>
            <div className="field">
              <TextField
                className="form-input"
                id="full_name"
                label="Name"
                onChange={updateUser}
              />
            </div>
            <div className="field">
              <TextField
                className="form-input"
                id="email"
                label="Email"
                onChange={updateUser}
              />
            </div>
            <div className="field">
              <PasswordField
                className="form-input"
                id="password"
                label="Password"
                onChange={updateUser}
              />
            </div>
            <div className="field">
              <PasswordField
                className="form-input"
                id="password_confirmation"
                label="Confirm Password"
                onChange={updateUser}
              />
            </div>
            <div
              className="form-error"
              style={{ visibility: showError ? "visible" : "hidden" }}
            >
              {" "}
              Passwords do not match{" "}
            </div>
            <div className="field">
              <TextField
                className="form-input"
                id="employee_id"
                label="Employee ID"
                onChange={updateUser}
              />
            </div>
            <div className="field">
              <Select
                className="form-input"
                id="job_site_id"
                label="Job Site"
                onChange={updateUser}
                options={jobSiteOptions}
              />
            </div>
            <div className="field">
              <Select
                className="form-input"
                id="supervisor_id"
                label="Supervisor"
                onChange={updateUser}
                options={supervisorOptions}
              />
            </div>
            <div className="submit-row">
              <Button onClick={submitHandler}>Add User</Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  })
);
