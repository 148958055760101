import React from "react";
import PropTypes from "prop-types";
import { WinConditionCell } from "./WinConditionCell";
import { CARD_HEIGHT, CARD_WIDTH } from "../../../utils/constants";

import "./WinConditionPattern.scss";

export const WinConditionPattern = (props) => {
  const { exact, onChange, pattern } = props;

  function cellInPattern(x, y) {
    const itemIndex = pattern.findIndex(
      (piece) => piece.col === y && piece.row === x
    );

    return itemIndex !== -1;
  }

  function onCellClick(x, y) {
    let isInPattern = false;
    if (cellInPattern(x, y)) {
      isInPattern = true;
    }

    return onChange(x, y, !isInPattern);
  }

  const cellProps = {};

  if (onChange) {
    cellProps.onClick = onCellClick;
  }

  return (
    <div className="WinConditionPattern">
      {[...Array(CARD_HEIGHT)].map((_, x) => {
        return (
          <div key={`${x}`} className="WinCondition__PatternRow">
            {[...Array(CARD_WIDTH)].map((_, y) => {
              return (
                <WinConditionCell
                  {...cellProps}
                  exact={exact}
                  key={y}
                  active={cellInPattern(x, y)}
                  x={x}
                  y={y}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

WinConditionPattern.propTypes = {
  pattern: PropTypes.array,
  onChange: PropTypes.func,
  exact: PropTypes.bool,
};
