import { action, makeObservable, observable } from "mobx";
import * as companiesWinConditionsService from "../services/companies/winConditions";
import * as arrayUtils from "../utils/array";

export class WinConditionsStore {
  @observable winConditions = [];
  @observable isLoading = true;
  @observable errorMessage;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setError(errorMessage) {
    this.errorMessage = errorMessage;
  }

  @action
  setWinConditions(winConditions) {
    this.winConditions = winConditions;
  }

  @action
  cacheWinConditions(newWinConditions) {
    const allWinConditions = [...newWinConditions, ...this.winConditions];

    const allWinConditionsUnique = arrayUtils.uniqueOnId(
      allWinConditions,
      "id"
    );

    this.setWinConditions(allWinConditionsUnique);
  }

  @action
  async retrieveWinConditionsForCompany(companyId) {
    try {
      const { token } = this.appStore;

      const response = await companiesWinConditionsService.getWinConditions(
        token,
        companyId
      );

      this.cacheWinConditions(response.data);
    } catch (err) {
      this.setError(err.message);
    }
  }

  @action
  async createWinConditionOnActiveCompany(payload) {
    const { companiesStore, token } = this.appStore;
    const { activeCompany } = companiesStore;

    await companiesWinConditionsService.createWinCondition(
      token,
      activeCompany.id,
      payload
    );

    await this.retrieveWinConditionsForCompany(activeCompany.id);
  }

  @action
  async deleteWinCondition(winConditionId) {
    const { companiesStore, token } = this.appStore;
    const { activeCompany } = companiesStore;

    await companiesWinConditionsService.deleteWinCondition(
      token,
      activeCompany.id,
      winConditionId
    );

    await this.retrieveWinConditionsForCompany(activeCompany.id);
  }
}
