import React from "react";
import { appStorePropTypes } from "../../../propTypes/stores";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Button } from "../../../controls";
import LogoutIcon from "@mui/icons-material/Logout";
import { withRouter } from "../../../utils/withRouter";

@inject("store")
@observer
@withRouter
export class UserOptions extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    store: appStorePropTypes,
  };

  onLogoutClick = async () => {
    const { router, store } = this.props;
    const { navigate } = router;

    try {
      await store.signOut();
    } catch (err) {
      console.log(err);
    }

    navigate("/sign_in");
  };

  render() {
    return (
      <div className="UserOptions">
        <Button
          type="text"
          className="UserOptions__Button"
          onClick={this.onLogoutClick}
        >
          <LogoutIcon />
        </Button>
      </div>
    );
  }
}
