import { action, makeObservable, observable } from "mobx";
import * as companiesContentsService from "../services/companies/contents";
import * as arrayUtils from "../utils/array";

export class ContentsStore {
  @observable contents = [];
  @observable isLoading = true;
  @observable errorMessage;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
  }

  @action
  setIsLoading(isLoading) {
    this.isLoading = isLoading;
  }

  @action
  setError(errorMessage) {
    this.errorMessage = errorMessage;
  }

  @action
  setContents(contents) {
    this.contents = contents;
  }

  @action
  cacheContents(newContents) {
    const allContent = [...newContents, ...this.contents];

    const allContentUnique = arrayUtils.uniqueOnId(allContent, "id");

    this.setContents(allContentUnique);
  }

  addContentToCache(content) {
    const newContent = [...this.contents];
    newContent.push(content);

    this.setContents(newContent);
  }

  @action
  async retrieveContentsForCompany(companyId) {
    try {
      const { token } = this.appStore;

      const response = await companiesContentsService.getContents(
        token,
        companyId
      );

      this.cacheContents(response.data);
    } catch (err) {
      this.setError(err.message);
    }
  }

  @action
  async createContentForCompany(companyId, contentPayload) {
    try {
      const { token } = this.appStore;

      const content = await companiesContentsService.createContent(
        token,
        companyId,
        contentPayload
      );

      this.addContentToCache(content);
    } catch (err) {
      this.setError(err.message);
    }
  }
}
