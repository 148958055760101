import React from "react";
import { appStorePropTypes } from "../../propTypes/stores";
import { withRouter } from "../../utils/withRouter";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { ContentTable } from "./ContentTable";

import "./AddMessage.scss";

@inject("store")
@observer
class AddMessage extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  componentDidMount() {
    const { store } = this.props;
    const { companiesStore, contentsStore, gameSessionsStore } = store;

    const companyId = companiesStore.activeCompany.id;

    gameSessionsStore.retrieveGameSessionsForCompany(companyId);
    contentsStore.retrieveContentsForCompany(companyId);
  }

  render() {
    return (
      <div className="page-wrap">
        <div className="back-done-row">
          <Link className="back-button" to={`/`}>
            {`< Back`}
          </Link>
          <Link className="done-button" to={`/`}>
            Done
          </Link>
        </div>
        <div className="message-table">
          <ContentTable />
        </div>
      </div>
    );
  }
}

export default withRouter(AddMessage);
