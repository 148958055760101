import { v4 as uuid } from "uuid";

export default function getTestIDs() {
  if (process.env.NODE_ENV !== "test") return {};

  const ids = new Map();
  const proxy = new Proxy(
    {},
    {
      get(obj, prop) {
        if (ids.has(prop) === false) {
          ids.set(prop, uuid());
        }
        return ids.get(prop);
      },
    }
  );
  return proxy;
}
