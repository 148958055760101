export function formatType(contentType) {
  switch (contentType) {
    case "Question::Multichoice":
      return "Multichoice";
    case "Question::TrueFalse":
      return "True/False";
    case "Question":
      return "Question";
    case "Content":
      return "Message";
  }
}
