import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./DateField.scss";

export class DateField extends React.Component {
  static defaultProps = {
    disabled: false,
    defaultValue: "",
    autoComplete: false,
  };

  static propTypes = {
    /** Additional class names for the component */
    className: PropTypes.string,
    /** Wether the input is disabled or not */
    disabled: PropTypes.bool,
    /** Callback function for when DateField's input is changed */
    onChange: PropTypes.func,
    /** Id associated with component */
    id: PropTypes.any,
    /** Label associated with component */
    label: PropTypes.string,
    /** Placeholder associated with component */
    placeholder: PropTypes.string,
    /** The default value of the DateField */
    defaultValue: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue,
    };
  }

  onChange = (event) => {
    const { disabled, id, onChange } = this.props;

    const { value } = event.target;

    this.setState({ value });

    if (!disabled) {
      if (onChange) {
        if (id) {
          onChange({
            id,
            value,
          });
        } else {
          onChange(value);
        }
      }
    }
  };

  render() {
    const { className, id, label } = this.props;

    const { value } = this.state;

    const dateFieldClass = classNames("DateField", className);
    return (
      <div className={dateFieldClass}>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          className="DateField__input"
          type="date"
          onChange={this.onChange}
          value={value}
        />
      </div>
    );
  }
}

export default DateField;
