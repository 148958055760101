import { axiosPut } from "./axiosRequests";
import { getEndpoint, requestHeaders } from "../utils/endpoint.js";

const WIN_ENDPOINT = (id) => getEndpoint(`api/v1.0/wins/${id}`);

export const updateWin = async (token, winId, payload) => {
  const response = await axiosPut(
    WIN_ENDPOINT(winId),
    {
      win: payload,
    },
    requestHeaders(token)
  );

  return response;
};
