import React from "react";
import PropTypes from "prop-types";
import { appStorePropTypes } from "../../propTypes/stores";
import { withRouter } from "../../utils/withRouter";
import { inject, observer } from "mobx-react";
import { Container } from "../../controls/Container";
import { Button, Text, TextField } from "../../controls";

import "./EditCompany.scss";

@inject("store")
@observer
class EditCompany extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    store: appStorePropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      company: {},
    };
  }

  componentDidMount() {
    const { router, store } = this.props;
    const { id } = router.params;
    const { companiesStore } = store;

    companiesStore.retrieveCompany(id);
  }

  onFieldChange = (fieldChange) => {
    const { company } = this.state;

    company[fieldChange.id] = fieldChange.value;

    this.setState({ company });
  };

  onSaveClick = async () => {
    const { company } = this.state;
    const { router, store } = this.props;
    const { companiesStore } = store;

    const { id } = router.params;

    await companiesStore.updateCompany(id, company);
  };

  render() {
    const { router, store } = this.props;
    const { id } = router.params;

    const { companiesStore } = store;
    const { isLoading } = companiesStore;

    const company = companiesStore.getCompanyById(id);

    return (
      !isLoading && (
        <Container className="CompanySettings middle">
          <Text type="H1">Settings</Text>
          <div className="CompanySettings__item">
            <Text type="H3">Company Name</Text>
            <TextField
              id="name"
              defaultValue={company.attributes.name}
              onChange={this.onFieldChange}
            />
          </div>
          <div className="CompanySettings__item">
            <Text type="H3">Address</Text>
            <TextField
              id="address"
              defaultValue={company.attributes.address}
              onChange={this.onFieldChange}
            />
          </div>
          <Button onClick={this.onSaveClick}>Save</Button>
        </Container>
      )
    );
  }
}

export default withRouter(EditCompany);
