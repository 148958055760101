import React from "react";
import { withRouter } from "../../utils/withRouter";
import { appStorePropTypes } from "../../propTypes/stores";
import { inject, observer } from "mobx-react";
import moment from "moment";
import classNames from "classnames";
import "./CurrentGame.scss";
import { WinnerTable } from "../../components/WinnerTable";
import { BingoRoundsCalendar } from "../../components/BingoRounds/Calendar";
import { BingoRoundsTable } from "../../components/BingoRounds/Table";
import { Button } from "../../controls";
import { EndGame } from "./EndGame";
import { Link } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TableViewIcon from "@mui/icons-material/TableView";

@inject("store")
@observer
class CurrentGame extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal_active: false,
      bingoRoundsView: "table",
    };
  }

  async componentDidMount() {
    const { store } = this.props;
    const { bingoRoundsStore, gameSessionsStore } = store;

    await bingoRoundsStore.retrieveBingoRoundsForGameSession(
      gameSessionsStore.activeGameSession.id
    );
  }

  setBingoRoundsViewToCalendar = () => {
    this.setState({ bingoRoundsView: "calendar" });
  };

  setBingoRoundsViewToTable = () => {
    this.setState({ bingoRoundsView: "table" });
  };

  render() {
    const { store } = this.props;
    const { bingoRoundsView } = this.state;
    const { bingoRoundsStore, companiesStore, gameSessionsStore } = store;

    const { activeGameSession } = gameSessionsStore;
    const { createdAt } = activeGameSession.attributes;

    const bingoRounds = bingoRoundsStore.getBingoRoundsForGameSession(
      activeGameSession.id
    );

    const isInCalendarMode = bingoRoundsView === "calendar";

    const createdAtFormatted = moment(createdAt).format("MM-DD-YY");

    const calendarViewClass = classNames("ViewOption", {
      "ViewOption--active": isInCalendarMode,
    });

    const tableViewClass = classNames("ViewOption", {
      "ViewOption--active": !isInCalendarMode,
    });

    return (
      <div className="CurrentGame">
        <div className="game-info-controls">
          <div className="game-id">{`Game ID: ${activeGameSession.id}`}</div>
          <div className="flex-row">
            <div className="start-date">{`Started: ${createdAtFormatted}`}</div>
            <Link to="/cards">
              <Button>See Cards</Button>
            </Link>
            <EndGame />
          </div>
        </div>
        <div className="winner-table">
          <WinnerTable />
        </div>
        <div className="line"></div>
        <div className="search-filter-new-row">
          <div className="BingoRoundsViewSelector">
            <Button
              text="text"
              className={tableViewClass}
              onClick={this.setBingoRoundsViewToTable}
            >
              <TableViewIcon />
            </Button>
            <Button
              type="text"
              className={calendarViewClass}
              onClick={this.setBingoRoundsViewToCalendar}
            >
              <CalendarMonthIcon />
            </Button>
          </div>
          <Link
            className="add-button"
            to={`/companies/${companiesStore.activeCompany.id}/current-game/add-messages`}
          >
            Add Message
          </Link>
        </div>
        <div className="message-table">
          {isInCalendarMode ? (
            <BingoRoundsCalendar bingoRounds={bingoRounds} />
          ) : (
            <BingoRoundsTable bingoRounds={bingoRounds} />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(CurrentGame);
