import React, { Fragment, useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Calendar,
  DateLocalizer,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.scss";

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default function DragAndDrop({ events, onMove, resizable }) {
  const [myEvents, setMyEvents] = useState(events);

  const localizer = momentLocalizer(moment);

  const moveEvent = useCallback(
    ({ end, event, isAllDay: droppedOnAllDaySlot = false, start }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }

      const endDate = moment(end).subtract(1, "d");

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end: endDate, allDay }];
      });

      onMove && onMove(event.id, start, end);
    },
    [setMyEvents, onMove]
  );

  const resizeEvent = useCallback(
    ({ end, event, start }) => {
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setMyEvents]
  );

  const defaultDate = useMemo(() => new Date(), []);

  return (
    <Fragment>
      <DragAndDropCalendar
        className="Calendar"
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        events={myEvents}
        localizer={localizer}
        onEventDrop={moveEvent}
        onEventResize={resizeEvent}
        popup
        resizable={resizable}
      />
    </Fragment>
  );
}

DragAndDrop.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  resizable: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.object),
  onMove: PropTypes.func,
};

DragAndDrop.defaultProps = {
  resizable: false,
};
