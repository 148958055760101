import { axiosGet, axiosPost } from "../axiosRequests";
import { getEndpoint, requestHeaders } from "../../utils/endpoint.js";

const COMPANY_USERS_ENDPOINT = (companyId) =>
  getEndpoint(`api/v1.0/companies/${companyId}/users`);

export const getCompanyUsers = async (token, companyId) => {
  const response = await axiosGet(
    COMPANY_USERS_ENDPOINT(companyId),
    {},
    requestHeaders(token)
  );

  return response;
};

export const postUser = async (token, companyId, user) => {
  const response = await axiosPost(
    COMPANY_USERS_ENDPOINT(companyId),
    {
      user,
    },
    requestHeaders(token)
  );

  return response;
};
