import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export class AdminPanelLink extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string,
  };

  render() {
    const { icon, title, url } = this.props;
    return (
      <Link to={url} className="AdminPanelLink">
        <div>{icon}</div>
        <div>{title}</div>
      </Link>
    );
  }
}
