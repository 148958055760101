import React from "react";
import { appStorePropTypes } from "../../propTypes/stores";
import { Table } from "../../components/Table/Table";
import { inject, observer } from "mobx-react";
import { AddContentButton } from "./AddContentButton";

const TABLE_COLUMNS = [
  {
    header: "MESSAGE",
    accessor: "message",
  },
  {
    header: "CATEGORY",
    accessor: "category",
  },
  {
    header: "ADD TO GAME",
    accessor: "action",
    width: "200px",
  },
];

@inject("store")
@observer
export class ContentTable extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  getContentData = () => {
    const { store } = this.props;
    const { contentsStore } = store;

    return contentsStore.contents.map((content) => {
      return {
        id: content.id,
        message: content.attributes.message,
        category: null,
        action: this.renderAddButton(content),
      };
    });
  };

  addContentToGame = async (content) => {
    const { store } = this.props;
    const { bingoRoundsStore, gameSessionsStore } = store;

    const activeSessionId = gameSessionsStore.activeGameSession.id;

    return await bingoRoundsStore.createBingoRound(activeSessionId, {
      content_id: content.id,
    });
  };

  renderAddButton = (content) => {
    return (
      <AddContentButton content={content} onClick={this.addContentToGame} />
    );
  };

  render() {
    const contentData = this.getContentData();

    return <Table headers={TABLE_COLUMNS} data={contentData}></Table>;
  }
}
