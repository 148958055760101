import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./TextField.scss";

export class TextField extends React.Component {
  static defaultProps = {
    disabled: false,
    defaultValue: "",
    autoComplete: false,
  };

  static propTypes = {
    /** Whether the chrome's autocomplete function should be on or off */
    autoComplete: PropTypes.bool,
    /** Additional class names for the component */
    className: PropTypes.string,
    /** Error message to be displayed */
    errorMessage: PropTypes.string,
    /** Callback function for when TextField's input is changed */
    onChange: PropTypes.func,
    /** Callback function for when the TextField is clicked */
    onClick: PropTypes.func,
    /** Callback function for when the TextField has a keyDown event */
    onKeyDown: PropTypes.func,
    /** Whether the text field's input can be changed */
    disabled: PropTypes.bool,
    /** Id associated with component */
    id: PropTypes.any,
    /** Label associated with component */
    label: PropTypes.string,
    /** Placeholder associated with component */
    placeholder: PropTypes.string,
    /** The default value of the TextField */
    defaultValue: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue,
    };
  }

  onChange = (event) => {
    const { disabled, id, onChange } = this.props;

    const { value } = event.target;

    this.setState({ value });

    if (!disabled) {
      if (onChange) {
        if (id) {
          onChange({
            id,
            value,
          });
        } else {
          onChange(value);
        }
      }
    }
  };

  onClick = (event) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(event);
    }
  };

  onKeyDown = (event) => {
    const { onKeyDown } = this.props;

    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  render() {
    const {
      autoComplete,
      className,
      disabled,
      errorMessage,
      id,
      label,
      placeholder,
    } = this.props;

    const { value } = this.state;

    const additionalProps = {};

    if (!autoComplete) {
      additionalProps.autoComplete = "off";
    }

    const textFieldClass = classNames(
      "TextField",
      { "TextField--disabled": disabled },
      { "TextField--error": errorMessage },
      className
    );
    return (
      <div className={textFieldClass}>
        {label && <label htmlFor={id}>{label}</label>}
        <input
          id={id}
          className="TextField__input"
          type="text"
          tabIndex={0}
          onChange={this.onChange}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          value={value}
          {...additionalProps}
        />
        {errorMessage && (
          <div className="TextField__message">{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default TextField;
