import React from "react";
import DownloadAppStore from "../../assets/DownloadAppStore.png";
import DownloadGooglePlay from "../../assets/DownloadGooglePlay.png";

import "./AppDownload.scss";

export const AppDownload = () => {
  return (
    <div className="AppDownload page-wrap">
      <h1>Welcome to Carrot Bingo!</h1>
      <div>Click below to download the mobile app to your device.</div>
      <div className="AppDownload__navigation flex-row">
        <img src={DownloadAppStore} alt="App Store" />
        <img src={DownloadGooglePlay} alt="Play Store" />
      </div>
    </div>
  );
};
