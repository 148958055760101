import { action, makeObservable, observable } from "mobx";
import * as contractsService from "../services/contracts";

export class ContractsStore {
  @observable contracts = [];
  @observable isLoading = false;

  constructor(appStore) {
    makeObservable(this);

    this.appStore = appStore;
    this.bannerStore = appStore.bannerStore;
  }

  @action
  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  @action
  setContracts = (contracts) => {
    this.contracts = contracts;
  };

  @action
  updateContract = async (contractId) => {
    this.setIsLoading(true);

    const token = this.appStore.token;
    await contractsService.putContract(token, contractId);

    this.setIsLoading(false);
  };

  @action
  retrieveContracts = async () => {
    this.setIsLoading(true);

    const token = this.appStore.token;
    const response = await contractsService.getContracts(token);

    const contracts = response.data;

    this.setContracts(contracts);

    this.setIsLoading(false);
  };
}

export default ContractsStore;
