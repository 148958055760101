import React from "react";
import { inject, observer } from "mobx-react";
import { appStorePropTypes } from "../../propTypes/stores";
import { formatType } from "../../utils/models/content";
import { Table } from "../../components/Table/Table";

import "./FullContentTable.scss";

const TABLE_COLUMNS = [
  {
    header: "MESSAGE",
    accessor: "message",
  },
  {
    header: "CATEGORY",
    accessor: "category",
  },
  {
    header: "TYPE",
    accessor: "type",
  },
  {
    header: "ANSWER",
    accessor: "answer",
  },
];

@inject("store")
@observer
export class FullContentTable extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };

  getAnswerString = (answer) => {
    if (answer === true || answer === false) {
      return answer.toString();
    }

    return answer;
  };

  getContentData = () => {
    const { store } = this.props;
    const { contentsStore } = store;

    return contentsStore.contents.map((content) => {
      return {
        id: content.id,
        message: content.attributes.message,
        category: content.attributes.category,
        type: formatType(content.attributes.type),
        answer: this.getAnswerString(content.attributes.answer),
      };
    });
  };

  render() {
    return (
      <Table
        className="FullContentTable"
        headers={TABLE_COLUMNS}
        data={this.getContentData()}
      ></Table>
    );
  }
}
