import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Switch.scss";

const ENTER_KEY = "Enter";

export class Switch extends React.Component {
  static defaultProps = {
    defaultValue: false,
  };

  static propTypes = {
    /** Additional class names for the component */
    className: PropTypes.string,
    /** ID for the input */
    id: PropTypes.string,
    /** Callback function for when switch's state is changed */
    onChange: PropTypes.func,
    /** Default state for the switch */
    defaultValue: PropTypes.bool,
    /** Whether the switch can be toggled or not */
    disabled: PropTypes.bool,
    /** The label of the input field */
    label: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { value: props.defaultValue };
  }

  onChange = (event) => {
    const { disabled, id, onChange } = this.props;

    const { value } = event.target;

    this.setState({ value });

    if (!disabled) {
      if (onChange) {
        if (id) {
          onChange({
            id,
            value,
          });
        } else {
          onChange(value);
        }
      }
    }
  };

  onClick = () => {
    const { disabled, id, onChange } = this.props;
    const { value } = this.state;
    const newValue = !value;

    if (!disabled) {
      this.setState({
        value: newValue,
      });

      if (onChange) {
        if (id) {
          onChange({
            id,
            newValue,
          });
        } else {
          onChange(newValue);
        }
      }
    }
  };

  onKeyDownHandler = (event) => {
    const { onChange } = this.props;
    if (onChange && event.key === ENTER_KEY) {
      this.onClick();
    }
  };

  render() {
    const { className, disabled, label } = this.props;
    const { value } = this.state;

    const switchClass = classNames(
      "switch",
      { "switch--on": value },
      { "switch--disabled": disabled }
    );

    const containerClass = classNames("SwitchContainer", className);

    return (
      <div className={containerClass}>
        {label && <label>{label}</label>}
        <div
          className={switchClass}
          onClick={this.onClick}
          onKeyDown={this.onKeyDownHandler}
          role="button"
          tabIndex={0}
        >
          <div className="switch__toggle" />
        </div>
      </div>
    );
  }
}

export default Switch;
