import * as axiosRequests from "../axiosRequests";
import { getEndpoint, requestHeaders } from "../../utils/endpoint.js";

const COMPANIES_CONTENTS_ENDPOINT = (companyId) =>
  getEndpoint(`api/v1.0/companies/${companyId}/contents`);

export const getContents = async (token, companyId) => {
  const response = await axiosRequests.axiosGet(
    COMPANIES_CONTENTS_ENDPOINT(companyId),
    {},
    requestHeaders(token)
  );

  return response.data;
};

export const createContent = async (token, companyId, content) => {
  const response = await axiosRequests.axiosPost(
    COMPANIES_CONTENTS_ENDPOINT(companyId),
    {
      content,
    },
    requestHeaders(token)
  );

  return response.data;
};
