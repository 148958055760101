import React from "react";
import PropTypes from "prop-types";
import { appStorePropTypes } from "../../propTypes/stores";
import { inject, observer } from "mobx-react";
import { Button, Container, PasswordField, TextField } from "../../controls";
import { Navigate } from "react-router-dom";
import { withRouter } from "../../utils/withRouter";

import "./Login.scss";

const ENTER_KEY = "Enter";

@inject("store")
@observer
@withRouter
class Login extends React.Component {
  static propTypes = {
    router: PropTypes.object,
    store: appStorePropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: null,
      password: null,
      errorMessage: null,
    };
  }

  onEmailChange = (item) => {
    this.setState({ email: item.value });
  };

  onPasswordChange = (item) => {
    this.setState({ password: item.value });
  };

  onSubmit = async () => {
    const { router, store } = this.props;
    const { email, password } = this.state;

    const signedIn = await store.signIn(email, password);

    if (signedIn) {
      router.navigate("/");
    } else {
      this.setState({ errorMessage: "Could not sign you in" });
    }
  };

  onKeyDown = (event) => {
    if (event.key === ENTER_KEY) {
      this.onSubmit();
    }
  };

  render() {
    const { store } = this.props;
    const { errorMessage } = this.state;
    return (
      <div className="bg-setter">
        <Container className="middle Login">
          <h1 className="Login-title">Welcome to Carrot</h1>
          <TextField
            label="Email"
            id="email"
            onChange={this.onEmailChange}
            onKeyDown={this.onKeyDown}
          />
          <PasswordField
            label="Password"
            id="password"
            onChange={this.onPasswordChange}
            onKeyDown={this.onKeyDown}
          />
          <Button onClick={this.onSubmit}>Login</Button>
          {errorMessage && <p>{errorMessage}</p>}
          {!store.isLoading && store.isAuthenticated && <Navigate to="/" />}
        </Container>
      </div>
    );
  }
}

export { Login };
