import React from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "../../utils/withRouter";
import "./PastGames.scss";
import { Table } from "../../components/Table";
import { appStorePropTypes } from "../../propTypes/stores";
import moment from "moment";

const TABLE_COLUMNS = [
  {
    header: "GAME ID",
    accessor: "id",
  },
  {
    header: "START DATE",
    accessor: "startDate",
  },
  {
    header: "END DATE",
    accessor: "endDate",
  },
  {
    header: "WINNERS",
    accessor: "winners",
  },
  {
    header: "TOTAL PRIZE",
    accessor: "total_prize",
  },
];

@inject("store")
@observer
class PastGames extends React.Component {
  static propTypes = {
    store: appStorePropTypes,
  };
  getWinnersForSession = (session) => {
    return session.attributes.wins.map(
      (win) => win.attributes.user.attributes.fullName
    );
  };

  getPastGames = () => {
    const { store } = this.props;

    const previousSessions = store.gameSessionsStore.previousSessions;

    return previousSessions.map((session) => {
      return {
        id: session.id,
        startDate: moment(session.attributes.createdAt).format("MM-DD-YY"),
        endDate: moment(session.attributes.finishedAt).format("MM-DD-YY"),
      };
    });
  };

  render() {
    const pastGamesData = this.getPastGames();

    return (
      <div className="page-wrap">
        <Table headers={TABLE_COLUMNS} data={pastGamesData} />
      </div>
    );
  }
}

export default withRouter(PastGames);
