import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Button.scss";

export class Button extends React.Component {
  static propTypes = {
    /** The children to render inside the button */
    children: PropTypes.node,
    /** Override the class of the button */
    className: PropTypes.string,
    /** A callback for when the button is clicked */
    onClick: PropTypes.func,
    /** Default types that provide well-defined CSS to the component */
    type: PropTypes.oneOf(["hazard", "primary", "warn", "text"]),
  };

  onClick = (event) => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(event);
    }
  };

  render() {
    const { children, className, type } = this.props;

    const buttonClass = classNames("Button", className, type);

    return (
      <button className={buttonClass} onClick={this.onClick}>
        {children}
      </button>
    );
  }
}
