import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const ENTER_KEY = "Enter";

export class DropdownMenu extends React.Component {
  static defaultProps = {
    isOpen: false,
  };

  static propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
  };

  onClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const { setIsOpen } = this.props;

    setIsOpen(false);
  };

  onKeyDown = (event) => {
    if (event.key === ENTER_KEY) {
      this.onClick(event);
    }
  };

  render() {
    const { children, isOpen } = this.props;

    const menuClass = classNames("DropdownMenu", {
      "DropdownMenu--open": isOpen,
    });

    return (
      <div
        className={menuClass}
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }
}

export default DropdownMenu;
