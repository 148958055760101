import PropTypes from "prop-types";
import {
  companyPropTypes,
  contentsPropTypes,
  currentUserPropTypes,
  gameSessionPropTypes,
  winConditionPropTypes,
} from "./models";

export const bannerStorePropTypes = PropTypes.shape({
  deleteBanner: PropTypes.func,
  banners: PropTypes.arrayOf(PropTypes.object),
});

export const winConditionsStorePropTypes = PropTypes.shape({
  winConditions: PropTypes.arrayOf(winConditionPropTypes),
});

export const gameSessionsStorePropTypes = PropTypes.shape({
  previousSessions: PropTypes.arrayOf(gameSessionPropTypes),
});

export const companiesStorePropTypes = PropTypes.shape({
  activeCompany: companyPropTypes,
  isLoading: PropTypes.bool,
});

export const contentsStorePropTypes = PropTypes.shape({
  contents: PropTypes.arrayOf(contentsPropTypes),
  retrieveContentsForCompany: PropTypes.func,
});

export const appStorePropTypes = PropTypes.shape({
  companiesStore: companiesStorePropTypes,
  contentsStore: contentsStorePropTypes,
  currentUser: currentUserPropTypes,
  token: PropTypes.string,
  winConditionsStore: winConditionsStorePropTypes,
  gameSessionsStore: gameSessionsStorePropTypes,
  signIn: PropTypes.func,
  isLoading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
});
